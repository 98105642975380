<style lang="scss" scoped>
/*Swiper*/
.swiper-container {
	padding-bottom: 1.5rem;
}
@screen md {
	.swiper-container {
		height: 100%;
		padding-bottom: 0;
	}
}
.swiper-slide {
	position: relative;
	overflow: hidden;
}
.swiper-slide img {
	width: 100%;
	width: calc(100% + 2px);
	max-width: 500px;
	margin: auto;
	height: 100%;
	object-fit: contain;
}
@screen md {
	.swiper-slide img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: 700px;
	}
}
.swiper-slide img.swiper-lazy {
	opacity: 0;
	@apply transition-opacity;
	@apply duration-300;
}
.swiper-slide img.swiper-lazy-loaded {
	opacity: 1;
}
#pagination {
	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	z-index: 1;
}
@screen md {
	#pagination {
		position: absolute;
		bottom: 2rem;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}
}
#pagination-progress {
	display: flex;
}
#pagination-progress-bar {
	transition: width 0.5s;
}
#pagination-fraction-index {
	display: flex;
}
#pagination-fraction-length {
	display: flex;
}
.button-prev {
	cursor: url("/assets/images/arrow-alt-left.svg"), auto !important;
}
.button-next {
	cursor: url("/assets/images/arrow-alt-right.svg"), auto !important;
}
</style>

<template>
	<div
		class="w-full top-header bg-white md:w-1/2 md:sticky md:h-full-header"
		style="min-height: 420px"
	>
		<transition name="fade" mode="in-out">
			<template v-if="$parent.loaded === true">
				<div id="product-slider" class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="image in productGallery">
							<img :data-src="image.image" class="swiper-lazy" alt="" />
						</div>
					</div>

					<div id="pagination">
						<div id="pagination-fraction-index" class="mr-4"></div>
						<div id="pagination-progress" class="flex w-16 h-px -mt-1 bg-white">
							<div id="pagination-progress-bar" class="h-full bg-black"></div>
						</div>
						<div id="pagination-fraction-length" class="ml-4"></div>
					</div>

					<div
						class="button-prev hidden md:block absolute left-0 top-0 w-1/2 h-full z-50"
					></div>
					<div
						class="button-next hidden md:block absolute right-0 top-0 w-1/2 h-full z-50"
					></div>
				</div>
			</template>
		</transition>
	</div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

import arrowLeft from "../components/svg/arrow-left.vue";
import arrowRight from "../components/svg/arrow-right.vue";

export default {
	components: {
		arrowLeft,
		arrowRight,
	},
	props: {
		productGallery: {},
	},
	updated() {
		var productSlider = new Swiper("#product-slider", {
			speed: 500,
			navigation: {
				prevEl: "#product-slider .button-prev",
				nextEl: "#product-slider .button-next",
			},
			loop: true,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
			// simulateTouch: false
		});

		// Get pagination progress elements
		var paginationProgress = document.getElementById("pagination-progress");
		var paginationProgressBar = document.getElementById(
			"pagination-progress-bar"
		);

		// Get pagination fraction elements
		var paginationFractionIndex = document.getElementById(
			"pagination-fraction-index"
		);
		var paginationFractionLength = document.getElementById(
			"pagination-fraction-length"
		);

		// Set index initial value
		var index = 1;

		// Get slides length. Accommodate for dulpicate slides.
		var slidesLength = productSlider.slides.length - 2;

		// Calculate progress
		var progress = index / slidesLength;

		// Set initial progressbar width
		paginationProgressBar.style.width = progress * 100 + "%";

		// Set fraction index initial value
		paginationFractionIndex.innerHTML =
			'<div class="w-4">0</div><div class="w-4">1</div>';

		// Set fraction length
		paginationFractionLength.innerHTML =
			'<div class="w-4">0</div><div class="w-4">' + slidesLength + "</div>";

		// On slide change
		productSlider.on("slideChange", function () {
			// Get current index
			index = productSlider.realIndex + 1;

			// Set fraction index
			paginationFractionIndex.innerHTML =
				'<div class="w-4">0</div><div class="w-4">' + index + "</div>";

			// Calculate progress
			progress = index / slidesLength;

			// Set current progressbar width
			paginationProgressBar.style.width = progress * 100 + "%";
		});
	},
};
</script>
