<style lang="scss" scoped>
.box {
	overflow: hidden;
	&--instagram {
		svg {
			width: 100%;
			height: auto;
			opacity: 0;
			transition: opacity 0.35s;
			backface-visibility: hidden;
		}
	}
}
.box--instagram:hover svg {
	opacity: 1;
}
.image-container {
	position: relative;
	padding-bottom: 139.84375%;
	overflow: hidden;
}
.product-bg-image,
.post-thumbnail {
	width: 100%;
	width: calc(100% + 2px);
	max-width: none;
	position: absolute;
	left: -1px;
	top: 0;
	opacity: 1;
	transition: opacity 0.5s;
	overflow: hidden;
	video {
		max-width: 100%;
	}
}
.post-thumbnail {
	top: 50%;
	transform: translateY(-50%);
}
.post-gallery {
	opacity: 0;
	transition: opacity 0.5s;
	pointer-events: none;
}
.post-gallery-image-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	padding-bottom: 139.84375%;
}
.post-gallery img {
	opacity: 0;
	transition: opacity 0.5s;
}
.post-gallery img.active {
	opacity: .7;
}
.box:hover .post-gallery {
	opacity: 1;
}
.post-gallery-image {
	width: 100%;
	width: calc(100% + 4px);
	max-width: none;
	position: absolute;
	left: -1px;
	top: 0;
}

.box:hover .product-bg-image,
.box:hover .post-thumbnail {
	opacity: 0.7;
}
.product-image-container {
	transform: translateX(100%);
	transition: transform 1s;
	transition-delay: 0s;
}
.product-image {
	/*Consistent rendering in chromium*/
	-webkit-backface-visibility: hidden;
}
.type-image-container {
	transform: translateX(100%);
	transition: transform 1.8s;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
}
.type-marquee {
	height: 100%;
	width: auto;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
}
.type-image {
	height: 100%;
	width: auto;
	max-width: none;
}
/*Animate the type*/
.box:hover .type-image-container {
	transform: translateX(0);
}
.box:hover .type-marquee {
	animation: slide-left 4s linear infinite;
}
/*Animate the product*/
.box:hover .product-image-container {
	transform: translateX(0);
	transition-delay: 0.3s;
}
.box:hover .product-image {
	animation: nudge 0.3s ease-in-out;
	animation-delay: 0.7s;
	animation-fill-mode: forwards;
}

@keyframes nudge {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(6deg);
	}
	100% {
		transform: rotate(0);
	}
}
@keyframes slide-left {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
</style>

<template>
	<div
		class="box w-1/2 md:w-1/3 lg:w-1/2 relative bg-black"
		:class="`box--${box.type}`"
	>
		<template v-if="box.type == 'product'">
			<router-link :to="box.productPageUri">
				<div class="image-container">
					<img class="product-bg-image" v-onload="box.productBgImage" alt="" />
				</div>
				<div class="absolute left-0 top-0 w-full h-full">
					<div class="type-image-container">
						<div class="type-marquee">
							<img class="type-image" v-onload="box.typeImage" alt="" />
							<img class="type-image" v-onload="box.typeImage" alt="" />
						</div>
						<div class="type-marquee">
							<img class="type-image" v-onload="box.typeImage" alt="" />
							<img class="type-image" v-onload="box.typeImage" alt="" />
						</div>
					</div>
				</div>
				<div
					v-if="box.productImage"
					class="absolute left-0 top-0 w-full h-full"
				>
					<div class="w-full h-full relative image-container">
						<div
							class="absolute w-full h-full left-0 top-0 px-5 py-8 product-image-container"
						>
							<img
								class="product-image w-full h-full object-contain object-center"
								v-onload="box.productImage"
								alt=""
							/>
						</div>
					</div>
				</div>
				<div
					class="absolute left-0 top-0 px-5 py-8 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-500 flex flex-col justify-between text-white uppercase"
				>
					<h2 class="text-2xl leading-tight whitespace-normal">
						{{ box.productPageTitle }}
					</h2>
					<div class="whitespace-normal">
						Products > {{ box.productPageTitle }}
					</div>
				</div>
			</router-link>
		</template>
		<template v-if="box.type == 'post'">
			<router-link :to="box.postPageUri">
				<div class="image-container">
					<img class="post-thumbnail" v-onload="box.postThumbnail" alt="" />
				</div>
				<div v-if="box.postGallery.length > 0" class="absolute left-0 top-0 w-full h-full bg-black post-gallery">
					<div
						v-for="image in box.postGallery"
						class="post-gallery-image-container"
					>
						<img
							:class="[image.isActive ? 'active' : '', 'post-gallery-image']"
							v-onload="image.url"
							alt=""
						/>
					</div>
				</div>
				<div
					@mouseenter="rotateGallery"
					@mouseleave="stopRotatingGallery"
					class="absolute left-0 top-0 px-5 py-8 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-500 flex flex-col justify-between text-white  uppercase"
				>
					<h2 class="text-2xl leading-tight whitespace-normal">
						{{ box.postPageTitle }}
					</h2>
					<div class="whitespace-normal">Blog</div>
				</div>
			</router-link>
		</template>
		<template v-if="box.type == 'instagram'">
			<a :href="box.link" target="_blank" rel="noopener">
				<div class="image-container">
					<template v-if="box.mediaType == 'image'">
						<img class="post-thumbnail" v-onload="box.file" />
					</template>
					<template v-if="box.mediaType == 'video'">
						<video class="post-thumbnail" :src="box.file" autoplay muted loop />
					</template>
				</div>
				<div class="absolute left-0 top-0 w-full h-full">
					<stickerIg></stickerIg>
				</div>
			</a>
		</template>
	</div>
</template>

<script>
import stickerIg from "./svg/sticker-ig.vue";
export default {
	components: {
		stickerIg,
	},
	props: {
		box: {},
	},
	data: function () {
		return {
			postGalleryRotating: false,
		};
	},
	methods: {
		onResize(event) {
			this.setTypeMarqueeWidth();
		},
		setTypeMarqueeWidth() {
			var typeMarquees = document.getElementsByClassName("type-marquee");
			var typeMarqueeHeight = 0;
			if (typeMarquees.length > 0) {
				typeMarqueeHeight = typeMarquees.item(0).offsetHeight;
				var typeMarqueeWidth = typeMarqueeHeight * 1.6391;
				for (var i = 0; i < typeMarquees.length; i++) {
					typeMarquees.item(i).style.width = typeMarqueeWidth + "px";
				}
			}
		},
		rotateGallery() {
			// console.log("start");
			this.postGalleryRotating = true;
		},
		stopRotatingGallery() {
			// console.log("stop");
			this.postGalleryRotating = false;
		},
		rotate(i) {
			// console.log(this.box.postPageTitle, i);
			this.box.postGallery.map((item) => (item.isActive = false));
			this.box.postGallery[i].isActive = true;
		},
		stopRotating() {
			this.box.postGallery.forEach((item) => (item.isActive = false));
		},
	},
	watch: {
		postGalleryRotating: function (state) {
			// console.log(state)
			const self = this;
			if (state == true && self.box.postGallery.length > 0) {
				var i = 0;
				self.rotate(i);
				var i = 1;
				window.intervalTimer = setInterval(function () {
					self.rotate(i);
					i++;
					if (i > self.box.postGallery.length - 1) {
						i = 0;
					}
				}, 700);
			} else {
				this.stopRotating(i);
				clearInterval(window.intervalTimer);
			}
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.setTypeMarqueeWidth();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},
};
</script>
