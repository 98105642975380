var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"mt-header min-h-full-header"},[_c('div',{key:this.productId,staticClass:"flex flex-wrap mb-16"},[_c('productGallery',{attrs:{"productGallery":_vm.product.gallery}}),_vm._v(" "),_c('div',{staticClass:"pt-12 pl-5 pb-12 pr-5 md:pr-0 w-full md:w-4/12 bg-white"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(this.loaded === true)?_c('div',[_c('div',{staticClass:"uppercase text-2xl leading-tight mb-6"},[_c('div',{staticClass:"product-line"},[_vm._v(_vm._s(_vm.product.line)+" LINE")]),_vm._v(" "),_c('div',[_vm._v("–")]),_vm._v(" "),_c('div',{staticClass:"text-lg"},[_c('text-reveal',{attrs:{"text":_vm.product.title}}),_vm._v(" "),_c('text-reveal',{attrs:{"text":_vm.product.subtitle}})],1)]),_vm._v(" "),(_vm.product.description)?[_c('h2',{staticClass:"uppercase text-xs font-bold mb-2"},[_vm._v("Description")]),_vm._v(" "),_c('div',{staticClass:"mb-8 text-xs cosmos-prose",domProps:{"innerHTML":_vm._s(_vm.product.description)}})]:_vm._e(),_vm._v(" "),(_vm.product.features)?[_c('h2',{staticClass:"uppercase text-xs font-bold mb-2"},[_vm._v("Features")]),_vm._v(" "),_c('div',{staticClass:"cosmos-prose text-xs mb-8",domProps:{"innerHTML":_vm._s(_vm.product.features)}})]:_vm._e(),_vm._v(" "),(_vm.product.application)?[_c('h2',{staticClass:"uppercase text-xs font-bold mb-2"},[_vm._v("Application")]),_vm._v(" "),_c('div',{staticClass:"cosmos-prose text-xs mb-8",domProps:{"innerHTML":_vm._s(_vm.product.application)}})]:_vm._e(),_vm._v(" "),(_vm.product.caps)?[_c('h2',{staticClass:"uppercase text-xs font-bold mb-2"},[_vm._v("Cap System")]),_vm._v(" "),_c('ul',{staticClass:"flex flex-wrap mb-8"},_vm._l((_vm.product.caps),function(cap){return _c('li',{staticClass:"flex w-full sm:w-1/2 md:w-full lg:w-1/2 mb-3"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
										callback: _vm.visibilityChanged,
										throttle: 300,
										once: true,
									}),expression:"{\n\t\t\t\t\t\t\t\t\t\tcallback: visibilityChanged,\n\t\t\t\t\t\t\t\t\t\tthrottle: 300,\n\t\t\t\t\t\t\t\t\t\tonce: true,\n\t\t\t\t\t\t\t\t\t}"}],staticClass:"cap-image"},[_c('img',{staticClass:"w-full",attrs:{"src":cap.image,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"uppercase text-xs"},[_vm._v(_vm._s(cap.title))]),_vm._v(" "),_c('div',{staticClass:"text-xs",domProps:{"innerHTML":_vm._s(cap.description)}})])])}),0)]:_vm._e(),_vm._v(" "),_c('h2',{staticClass:"text-xs uppercase font-bold"},[_vm._v("Color Range")]),_vm._v(" "),_c('ul',{staticClass:"flex flex-wrap mb-8"},_vm._l((_vm.product.colorRange),function(color){return _c('li',{staticClass:"group w-1/2 sm:w-1/3 lg:w-1/4"},[_c('router-link',{attrs:{"to":color.uri}},[_c('ul',{staticClass:"text-xxs uppercase mt-4 mb-1 transition-opacity duration-300 opacity-0 group-hover:opacity-100"},[_c('li',{staticClass:"whitespace-no-wrap overflow-ellipsis overflow-hidden"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(color.code)+"\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('li',{staticClass:"truncate"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(color.name)+"\n\t\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"color-pill rounded h-2 w-20",class:{ 'is-active': color.self },style:([
										color.imageColor != 'true'
											? { 'background-color': color.value }
											: { backgroundImage: 'url(' + color.value + ')' } ])})])],1)}),0),_vm._v(" "),(_vm.product.instructions)?[_c('h2',{staticClass:"uppercase text-xs font-bold mb-2"},[_vm._v("\n\t\t\t\t\t\t\tInstructions for use\n\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"text-xs mb-8 cosmos-prose",domProps:{"innerHTML":_vm._s(_vm.product.instructions)}})]:_vm._e(),_vm._v(" "),(_vm.product.pdf)?_c('c-link',{staticClass:"block uppercase text-xs font-bold mb-20",attrs:{"text":"Download product info as PDF","url":_vm.product.pdf,"newTab":"true"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
								callback: _vm.visibilityChanged,
								throttle: 300,
								once: true,
							}),expression:"{\n\t\t\t\t\t\t\t\tcallback: visibilityChanged,\n\t\t\t\t\t\t\t\tthrottle: 300,\n\t\t\t\t\t\t\t\tonce: true,\n\t\t\t\t\t\t\t}"}],staticClass:"share-label uppercase text-xs mr-6 pt-4 pb-3"},[_vm._v("\n\t\t\t\t\t\t\tShare this product:\n\t\t\t\t\t\t")]),_vm._v(" "),_c('ul',{staticClass:"space-x-6 flex"},[_c('li',{staticClass:"wiggle"},[_c('a',{attrs:{"href":_vm.product.fbShareUrl,"target":"_blank","rel":"noopener"}},[_c('icon-fb')],1)]),_vm._v(" "),_c('li',{staticClass:"wiggle"},[_c('a',{attrs:{"href":_vm.product.ttShareUrl,"target":"_blank","rel":"noopener"}},[_c('icon-tt')],1)]),_vm._v(" "),_c('li',{staticClass:"wiggle"},[_c('a',{attrs:{"href":_vm.product.liShareUrl,"target":"_blank","rel":"noopener"}},[_c('icon-li')],1)]),_vm._v(" "),_c('li',{staticClass:"wiggle"},[_c('icon-pt')],1),_vm._v(" "),_c('li',{staticClass:"wiggle"},[_c('a',{attrs:{"href":_vm.product.mlShareUrl,"target":"_blank","rel":"noopener"}},[_c('icon-ml')],1)])])])],2):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"hidden w-full bg-white md:block md:w-2/12 md:sticky md:pl-0 top-header h-6 text-right pl-5 pr-6 pt-12"},[_c('c-router-link',{staticClass:"block uppercase text-xs font-bold",attrs:{"text":_vm.$t('links.backToProducts'),"url":_vm.parentLink}})],1)],1),_vm._v(" "),_c('similar-products',{attrs:{"similarProducts":_vm.product.similarProducts}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }