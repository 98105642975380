<style lang="css" scoped>
li a {
	position: relative;
}
li a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
ul.is-negative li a::after {
	@apply bg-white;
}
li a:hover::after {
	transform: scale(1);
	transform-origin: left;
}

li a.router-link-active::after {
	transform: scale(1);
}
</style>

<template>
	<nav
		id="page-navigation-desktop"
		class="hidden md:block md:relative md:ml-4 md:mt-0 md:p-0"
	>
		<ul
			:class="{ 'is-negative': $parent.isNegative }"
			class="flex flex-col md:flex-row md:items-center md:-mx-4"
		>
			<li v-for="item in items" class="md:px-4">
				<template v-if="item.uri == 'products'">
					<a
						@click="toggleMenu()"
						:class="{ 'router-link-active': routeIsProducts }"
						class="uppercase font-bold text-xs cursor-pointer"
					>
						{{ item.title }}
					</a>
				</template>
				<template v-else>
					<router-link :to="'/' + item.uri" class="uppercase font-bold text-xs">
						{{ item.title }}
					</router-link>
				</template>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	props: {
		items: {},
	},
	computed: {
		routeIsProducts: function () {
			return this.$route.matched.some(({ name }) =>
				/products|subcategory$/.test(name)
			);
		},
	},
	methods: {
		toggleMenu: function () {
			this.$root.prodMenuDtOpen = !this.$root.prodMenuDtOpen;
			this.$root.prodsFilterOpen = false;
		},
	},
};
</script>
