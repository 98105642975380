<style lang="css" scoped>
a {
	display: inline-block;
	position: relative;
}
a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.15rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
a:hover::after {
	transform: scale(1);
	transform-origin: left;
}
</style>

<template>
	<router-link :to="url">
		{{ text }}
	</router-link>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
			default: "Text",
		},
		url: {},
	},
	data: function () {
		return {};
	},
};
</script>
