<style lang="css" scoped>
/*Links*/
ul li a {
	position: relative;
}
ul li a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-white;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
ul li a:hover::after {
	transform: scale(1);
	transform-origin: left;
}
ul li a.is-active::after {
	transform: scale(1);
}

/*Tab gallery styles*/
ul li figure {
	height: 100%;
}
ul li.half {
	@apply w-full;
}
@screen sm {
	ul li.half {
		@apply w-1/2;
	}
}
ul li.small {
	@apply w-full;
}
@screen sm {
	ul li.small {
		@apply w-1/3;
	}
}
ul li.medium {
	@apply w-full;
}
@screen sm {
	ul li.medium {
		@apply w-2/3;
	}
	ul li.medium {
		@apply w-2/3;
	}
}
ul li.large {
	@apply w-full;
}
</style>

<style>
.gallery-panels img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.private-label-tab h3 {
	@apply text-2xl;
	@apply mb-4;
}
.private-label-tab p {
	@apply mb-8;
}
.private-label-tab ul {
	@apply mb-8;
	@apply pl-6;
	list-style-type: '— ';
}
</style>

<template>
	<main class="mt-header text-white min-h-full-header overflow-hidden">
		<div class="flex flex-wrap inner-px py-5 -mx-5 pt-20">
			<div class="w-full px-5 md:w-2/5">
				<h2 class="text-5xl leading-tight mb-6 md:mb-0">
					<text-reveal :text="lead"></text-reveal>
				</h2>
			</div>

			<div class="w-full px-5 text-2xl md:w-3/5">
				<div v-for="text in texts" class="mb-3">
					<text-reveal :text="text.content"></text-reveal>
				</div>
			</div>
		</div>

		<div id="tabs" class="pt-5">
			<!-- Tab list -->
			<div
				class="top-header h-full inner-px -mx-5 pt-8 pb-6 sticky bg-black z-10"
			>
				<ul
					class="w-full md:ml-auto md:w-3/5 px-5 uppercase flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8"
				>
					<li v-for="tab in tabs">
						<a
							:class="{ 'is-active': showTabPanel === tab.name }"
							class="cursor-pointer"
							@click="
								showTabPanel = tab.name;
								scrollTop();
							"
							>{{ tab.name }}</a
						>
					</li>
				</ul>
			</div>

			<div class="inner-px -mx-5">
				<!-- Tab text panels -->
				<div
					class="w-full pt-10 px-5 md:w-3/5 ml-auto"
					style="min-height: 100px"
				>
					<transition name="" mode="out-in">
						<template v-for="tab in tabs" v-if="showTabPanel === tab.name">
							<div
								:key="tab.name"
								v-html="tab.text"
								class="private-label-tab"
							></div>
						</template>
					</transition>
				</div>
			</div>
			<div class="flex gallery-panels">
				<!-- Tab gallery panels -->
				<transition name="fade" mode="out-in">
					<ul
						:key="tab.name"
						class="flex flex-wrap -mx-3"
						v-for="tab in tabs"
						v-if="showTabPanel === tab.name"
					>
						<li
							v-for="file in tab.gallery"
							class="px-1 mt-2"
							:class="file.style"
						>
							<image-reveal
								v-if="file.type == 'image'"
								:imageSrc="file.url"
								bgColor="bg-black"
							></image-reveal>
							<video-reveal
								v-if="file.type == 'video'"
								:videoSrc="file.url"
								bgColor="bg-black"
							></video-reveal>
						</li>
					</ul>
				</transition>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			tabs: "",
			showTabPanel: null,
			lead: "",
			texts: "",
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		scrollTop: function () {
			const id = "tabs";
			const yOffset = -10;
			const element = document.getElementById(id);
			const y =
				element.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: "auto" });
		},
		getData: function () {
			this.endpoint = this.$router.currentRoute.path + ".json";
			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}
			axios.get(this.endpoint).then((response) => {
				return (
					(this.lead = response.data.lead),
					(this.texts = response.data.texts),
					(this.tabs = response.data.tabs),
					(this.showTabPanel = this.tabs[0].name)
				);
			});
		},
	},
};
</script>
