<style lang="css" scoped>
div {
	position: relative;
}
div::after {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: white;
	transition: width 0.25s;
}
div.is-focused {
}
input {
	@apply py-2;
}
</style>

<template>
	<div>
		<label class="uppercase text-2xl font-bold block" :for="id">
			{{ label }}
		</label>
		<input
			class="w-full bg-transparent"
			:id="id"
			type="text"
			autocomplete="off"
			autocorrect="off"
			spellcheck="false"
			@focus="isFocused = true"
			@blur="isFocused = false"
		/>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
			default: "Label",
		},
	},
	data: function () {
		return {
			isFocused: false,
		};
	},
};
</script>
