var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'mailto')?_c('a',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
			callback: _vm.visibilityChanged,
			throttle: 50,
			once: true,
		}),expression:"{\n\t\t\tcallback: visibilityChanged,\n\t\t\tthrottle: 50,\n\t\t\tonce: true,\n\t\t}"}],class:[
			_vm.isRevealed ? 'is-active' : '',
			_vm.negative ? 'button--negative' : '',
			'button flex justify-center relative' ],attrs:{"href":("mailto:" + _vm.link + "?subject=" + _vm.subject)}},[_c('div',{staticClass:"button__bg"}),_vm._v(" "),_c('div',[_c('text-reveal',{attrs:{"text":_vm.text}})],1)]):_vm._e(),_vm._v(" "),(_vm.type === 'external-link')?_c('a',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
			callback: _vm.visibilityChanged,
			throttle: 50,
			once: true,
		}),expression:"{\n\t\t\tcallback: visibilityChanged,\n\t\t\tthrottle: 50,\n\t\t\tonce: true,\n\t\t}"}],class:[
			_vm.isRevealed ? 'is-active' : '',
			_vm.negative ? 'button--negative' : '',
			'button flex justify-center relative' ],attrs:{"href":_vm.link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"button__bg"}),_vm._v(" "),_c('div',[_c('text-reveal',{attrs:{"text":_vm.text}})],1)]):_vm._e(),_vm._v(" "),(_vm.type === 'router-link')?_c('router-link',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
			callback: _vm.visibilityChanged,
			throttle: 50,
			once: true,
		}),expression:"{\n\t\t\tcallback: visibilityChanged,\n\t\t\tthrottle: 50,\n\t\t\tonce: true,\n\t\t}"}],class:[
			_vm.isRevealed ? 'is-active' : '',
			_vm.negative ? 'button--negative' : '',
			'button flex justify-center relative' ],attrs:{"to":_vm.link}},[_c('div',{staticClass:"button__bg"}),_vm._v(" "),_c('div',[_c('text-reveal',{attrs:{"text":_vm.text}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }