<style lang="css" scoped>
/*Links*/
ul li a {
	position: relative;
}
ul li a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-white;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
ul li a:hover::after {
	transform: scale(1);
	transform-origin: left;
}
ul li a.is-active::after {
	transform: scale(1);
}

/*Video*/
.hero-video {
	cursor: url("/assets/images/unmute.svg"), auto;
}
.hero-video.unmuted {
	cursor: url("/assets/images/mute.svg"), auto;
}

svg {
	position: relative;
	z-index: 1;
}
.hero-video video {
	left: 1px;
	top: 1px;
	position: absolute;
	height: 100%;
	max-width: none;
}

/*Tab gallery styles*/
ul li figure {
	height: 100%;
}
ul li.half {
	@apply w-full;
}
@screen sm {
	ul li.half {
		@apply w-1/2;
	}
}
ul li.small {
	@apply w-full;
}
@screen sm {
	ul li.small {
		@apply w-1/3;
	}
}
ul li.medium {
	@apply w-full;
}
@screen sm {
	ul li.medium {
		@apply w-2/3;
	}
	ul li.medium {
		@apply w-2/3;
	}
}
ul li.large {
	@apply w-full;
}

.group:hover .animation-swipe {
	animation-play-state: running;
}
.animation-swipe {
	animation-name: swipe;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}
@keyframes swipe {
	0% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(-5px);
	}
}
</style>

<style>
.gallery-panels img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.about-tab h3 {
	@apply text-2xl;
	@apply mb-4;
}
.about-tab p {
	@apply mb-8;
}
.about-tab ul {
	@apply mb-8;
	@apply pl-6;
	list-style-type: "— ";
}
</style>

<template>
	<main class="mt-header text-white min-h-full-header overflow-hidden">
		<!-- The video -->
		<div :class="[!videoMuted ? 'unmuted' : '', 'hero-video inner-px py-5']">
			<div
				class="relative text-black overflow-hidden"
				@click="videoMuted = !videoMuted"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1200 732"
				>
					<g>
						<path
							d="M1113.2,0v401.9h41.2v329.9H869c-32.6,0.2-64.9-6.3-94.9-19c-29.1-12.2-55.5-29.9-77.8-52.2
		c-45.6-45.8-71.1-107.9-71-172.5V0H0v365.6c0-48.3,9.6-96.5,28.8-141.9c18.9-43.1,45.8-82.2,79.3-115.2
		c33.3-33.2,72.6-59.7,115.9-78.1c45.1-19.6,93.8-29.4,143-29h244V337h-37.3c-16.4,1.5-28.4,16-26.9,32.3
		c1.3,14.3,12.6,25.7,26.9,27h35.6V732H366.9c-49.2,0.2-97.8-9.7-143-29.3C136,665.4,66.1,595.4,28.8,507.5
		C9.6,462.2,0,413.9,0,365.6V732h1200V32c0,17.7-14.3,32.1-32,32.2c-0.1,0-0.2,0-0.2,0c-17.5-0.3-31.5-14.7-31.2-32.2c0,0,0,0,0-0.1
		c0-17.5,14.2-31.7,31.7-31.7c17.5,0,31.7,14.2,31.7,31.8V0H1113.2z"
						/>
						<path
							d="M1169.7,24.4h-5.1V31l4.9-0.2c2.9,0,4.4-1,4.4-3.2C1173.9,25.6,1172.2,24.4,1169.7,24.4z"
						/>
						<path
							d="M1168.3,4.1c-15.5,0-28,12.6-28,28.1c0,0.1,0,0.2,0,0.2c-0.1,15.5,12.3,28.2,27.8,28.3c15.7-0.1,28.3-12.9,28.3-28.5
		c0,0,0,0,0,0C1196.3,16.7,1183.8,4.1,1168.3,4.1z M1173.7,48.8l-6.6-9.8h-2.4v9.8h-11.2V15.1h16.6c4.4-0.4,8.8,1,12.2,3.9
		c2,2.2,3.1,5.1,2.9,8.1c0.1,4.5-2.5,8.5-6.6,10.2l7.8,11.5H1173.7z"
						/>
					</g>
				</svg>
				<video
					:muted="videoMuted"
					playsinline=""
					autoplay=""
					loop=""
					:src="video"
				></video>
			</div>
		</div>

		<div class="flex flex-wrap inner-px py-5 -mx-5">
			<div class="w-full px-5 md:w-2/5">
				<h2 class="text-5xl leading-tight mb-6 md:mb-0">
					<text-reveal :text="lead"></text-reveal>
				</h2>
			</div>

			<div class="w-full px-5 text-2xl md:w-3/5">
				<div v-for="text in texts" class="mb-3">
					<text-reveal :text="text.content"></text-reveal>
				</div>
			</div>
		</div>

		<div id="tabs" class="pt-5 pb-20">
			<!-- Tab list -->
			<div
				class="top-header h-full inner-px -mx-5 pt-8 pb-6 sticky bg-black z-10"
			>
				<ul
					class="w-full md:ml-auto md:w-3/5 px-5 uppercase flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8"
				>
					<li v-for="tab in tabs">
						<a
							:class="{ 'is-active': showTabPanel === tab.name }"
							class="cursor-pointer"
							@click="
								showTabPanel = tab.name;
								scrollTop();
							"
							>{{ tab.name }}</a
						>
					</li>
				</ul>
			</div>

			<div class="inner-px -mx-5">
				<!-- Tab text panels -->
				<div
					class="w-full pt-10 px-5 md:w-3/5 ml-auto"
					style="min-height: 100px"
				>
					<transition name="" mode="out-in">
						<template v-for="tab in tabs" v-if="showTabPanel === tab.name">
							<div :key="tab.name" v-html="tab.text" class="about-tab"></div>
						</template>
					</transition>
				</div>
			</div>
			<div class="flex gallery-panels">
				<!-- Tab gallery panels -->
				<transition name="fade" mode="out-in">
					<ul
						:key="tab.name"
						class="flex flex-wrap -mx-3"
						v-for="tab in tabs"
						v-if="showTabPanel === tab.name"
					>
						<li
							v-for="file in tab.gallery"
							class="px-1 mt-2"
							:class="file.style"
						>
							<image-reveal
								v-if="file.type == 'image'"
								:imageSrc="file.url"
								bgColor="bg-black"
							></image-reveal>
							<video-reveal
								v-if="file.type == 'video'"
								:videoSrc="file.url"
								bgColor="bg-black"
							></video-reveal>
						</li>
					</ul>
				</transition>
			</div>
		</div>

		<!-- Catalogue -->
		<div class="inner-px">
			<div class="py-24 border-solid border-t border-b border-white mb-20">
				<div class="flex flex-wrap -mx-5">
					<div class="w-full px-5 mb-20 md:w-2/5 md:mb-0">
						<h2 class="text-4xl leading-tight mb-10">
							<text-reveal :text="catalogue.heading"></text-reveal>
						</h2>

						<ul class="text-2xl space-y-4">
							<li class="flex items-center group">
								<a
									target="_blank"
									rel="noopener noreferrer"
									class="mr-5"
									:href="catalogue.pdf"
									>{{ catalogue.title }}</a
								>
								<arrow-alt-right
									class="opacity-0 group-hover:opacity-100 transition-opacity duration-300 animation-swipe"
								></arrow-alt-right>
							</li>
						</ul>
					</div>

					<div class="w-full px-5 text-2xl md:w-3/5 md:text-3xl">
						<div class="mx-auto w-full">
							<a
								target="_blank"
								rel="noopener noreferrer"
								:href="catalogue.pdf"
							>
								<image-reveal
									:imageSrc="catalogue.image"
									bgColor="bg-black"
								></image-reveal>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import arrowAltRight from "../svg/arrow-alt-right.vue";

export default {
	components: {
		arrowAltRight,
	},
	data: function () {
		return {
			tabs: "",
			showTabPanel: null,
			catalogues: "",
			texts: "",
			video: null,
			lead: "",
			catalogue: "",
			videoMuted: true,
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		scrollTop: function () {
			const id = "tabs";
			const yOffset = -40;
			const element = document.getElementById(id);
			const y =
				element.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: "smooth" });
		},
		getData: function () {
			this.endpoint = this.$router.currentRoute.path + ".json";
			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}
			axios
				.get(this.endpoint)
				.then(
					(response) => (
						(this.video = response.data.video),
						(this.lead = response.data.lead),
						(this.texts = response.data.texts),
						(this.tabs = response.data.tabs),
						(this.showTabPanel = this.tabs[0].name),
						(this.catalogue = response.data.catalogue)
					)
				);
		},
	},
};
</script>
