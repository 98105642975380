<style lang="css">
.productsFilters,
.productsFilters > * {
	cursor: url("/assets/images/hand.svg"), auto !important;
}
.productsFilters input {
	cursor: url("/assets/images/hand.svg"), auto !important;
}
.productsFilters label {
	cursor: url("/assets/images/hand.svg"), auto !important;
}
.simplebar-track.simplebar-vertical {
	width: 4px;
}
.simplebar-scrollbar {
	background: white;
}

@media (min-width: 820px) {
	.filter-group-container {
		max-height: 1028px;
	}
}
</style>

<template>
	<transition
		name="slide-right"
		@before-enter="beforeEnter"
		@before-leave="beforeLeave"
		@after-leave="afterLeave"
	>
		<div
			v-if="$root.prodsFilterOpen && $route.name === 'products'"
			class="productsFilters sticky z-50 left-0 top-header h-full-header w-full md:w-1/2 bg-black text-white"
		>
			<div
				class="inner-pl-mobile inner-pl h-full flex flex-col justify-between py-6 pr-6 md:pr-10"
				data-simplebar
			>
				<div>
					<div class="w-full flex justify-between items-center mb-6 md:mb-20">
						<h2 class="text-sm font-bold uppercase md:text-2xl">
							Choose your tools
						</h2>
						<div
							@click="
								$root.prodsFilterOpen = false;
								clearAllFilters();
							"
							class="w-6 h-6 md:w-8 md:h-8 opacity-75 hover:opacity-100"
						>
							<close></close>
						</div>
					</div>

					<div class="filter-group-container flex flex-wrap lg:flex-col">
						<div v-for="filterGroup in filterGroups" class="w-full lg:w-1/2">
							<h3 class="text-xs font-bold uppercase mb-4 pl-6">
								{{ filterGroup.title }}
							</h3>
							<ul class="space-y-4 mb-10">
								<li v-for="filter in filterGroup.filters">
									<c-checkbox-filter
										isSmall
										:label="filter.label"
										:id="filter.name"
										:input-value="filter.name"
										v-model="filtersActive"
									>
										></c-checkbox-filter
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<button
					@click="$root.prodsFilterOpen = false"
					class="font-bold uppercase text-xs mx-auto block leading-loose md:hidden absolute bottom-0 bg-black p-2 w-full left-0 text-center"
				>
					Εφαρμογή φιλτρων
				</button>
			</div>
		</div>
	</transition>
</template>

<script>
import "simplebar";
import "simplebar/dist/simplebar.css";

import close from "../components/svg/close.vue";

export default {
	components: {
		close,
	},
	data: function () {
		return {
			all: "",

			filterGroups: null,

			filtersActive: [],

			filtersEndpoint: "/product-filters.json",
		};
	},
	computed: {
		// Build the uri that is going to be used for the endpoint
		uri: function () {
			let uri = "";
			if (this.$root.prodsFilterOpen) {
				// If the filters menu is open
				// We need to search all products so we use products as the base uri
				uri = "/products.json/";
				let params = "";
				if (this.filtersActive.length) {
					// If there are active filters
					// Add them as params
					params = "filters:" + this.filtersActive + "/";
					// Add params to base uri
					uri = uri.concat(params);
				}
			} else {
				// If the filters menu is closed
				// Get the products of the page
				uri = `${this.$router.currentRoute.path}.json`;
			}

			return uri;
		},
	},
	mounted() {
		console.log("Product filter were mounted");

		// The clear all filters button has been clicked
		this.$root.$on("filtersCleared", (filtersCleared) => {
			this.clearAllFilters();
		});

		this.getFilters();
	},
	watch: {
		// When the list of active filters changes
		filtersActive: function () {
			console.log("The list of active filters has changed");
			// And finally make a call to the backend
			this.announceEndpoint();
		},
	},
	methods: {
		// Announce the computed uri
		// It is going to get picked by products component
		announceEndpoint() {
			console.log("announcing computed uri", this.uri);
			this.$root.$emit("filteredUri", this.uri);
		},
		getFilters() {
			if (this.$root.language == "el") {
				this.filtersEndpoint = "/el/product-filters.json";
			}
			axios
				.get(this.filtersEndpoint)
				.then(
					(response) => (
						(this.all = response.data.all),
						(this.filterGroups = response.data.filterGroups)
					)
				);
		},
		clearAllFilters() {
			console.log("Clear all filters");
			this.filtersActive = [];
		},
		beforeEnter: function (el) {
			this.$root.$emit("filtersState", "beforeEnter");
		},
		beforeLeave: function (el) {
			this.$root.$emit("filtersState", "beforeLeave");
		},
		afterLeave: function (el) {
			this.$root.$emit("filtersState", "afterLeave");
		},
	},
};
</script>
