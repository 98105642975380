<style lang="css" scoped>
/*Img placeholder*/
figure {
	width: 100%;
	height: 0;
	padding-bottom: 100%;
}
figure img {
	opacity: 1;
	@apply transition-opacity;
	@apply duration-300;
}
figure img[data-src] {
	opacity: 0;
}

button {
	position: relative;
}
button::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.15rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
button:hover::after {
	transform: scale(1);
	transform-origin: left;
}

.sticker-new {
	position: absolute;
	right: 16px;
	top: 16px;
	animation-name: spin;
	animation-duration: 8s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	width: 40px;
	height: 40px;
}

.sticker-new svg {
	width: 100%;
	height: auto;
}

@screen sm {
	.sticker-new {
		width: 80px;
		height: 80px;
	}
}

@screen md {
	.sticker-new {
		width: 120px;
		height: 120px;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>

<template>
	<main class="mt-header min-h-full-header">
		<breadcrumbs v-if="!isFiltered && filtersLeft" :info="info"></breadcrumbs>

		<div class="flex">
			<products-filters></products-filters>

			<div v-if="filtersLeft" class="w-full" :key="this.subcategory">
				<template v-if="info.description">
					<div
						class="inner-px w-full text-2xl md:text-3xl"
						style="max-width: 26em"
					>
						<text-reveal :text="info.description"></text-reveal>
					</div>
				</template>
				<ul id="products-list" class="w-full flex flex-wrap mt-8 mb-20">
					<li
						v-for="product in products"
						:key="product.uri"
						class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 relative"
					>
						<router-link :to="product.uri">
							<figure>
								<img :src="product.thumbnail" alt="" loading="lazy" />
							</figure>
							<div class="sticker-new" v-if="product.isNew === 'true'">
								<new-en v-if="$root.language == 'en'"></new-en>
								<new-el v-if="$root.language == 'el'"></new-el>
							</div>
							<div
								class="absolute left-0 top-0 p-4 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-500"
								:style="{ 'background-color': product.color }"
							>
								<h2
									class="uppercase text-lg lg:text-2xl leading-tight absolute z-10"
								>
									{{ product.title }}<br />
									{{ product.subtitle }}
								</h2>
								<div
									v-if="product.imageColor == 'true'"
									class="w-full h-full absolute left-0 top-0 opacity-50"
									:style="{ backgroundImage: 'url(' + product.color + ')' }"
								></div>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
			<div v-if="!filteredLeft" class="w-0 md:w-1/2 relative">
				<div
					class="hidden md:flex justify-between p-5 w-full ml-auto sticky left-0 z-40"
					v-if="!filteredLeft"
					style="top: 70px"
				>
					<div class="flex uppercase text-xs">
						{{ $t("labels.found") + ` ${info.count} ` + $t("labels.products") }}
					</div>
					<text-button
						:text="$t('buttons.clearAllFilters')"
						@click.native="clearAll"
					>
					</text-button>
				</div>
				<ul
					id="products-list"
					class="flex flex-wrap pt-8 pb-20"
					:key="this.subcategory"
				>
					<li
						v-for="product in products"
						:key="product.uri"
						class="w-1/2 relative"
					>
						<router-link :to="product.uri">
							<figure>
								<img :src="product.thumbnail" alt="" loading="lazy" />
							</figure>
							<div
								class="absolute left-0 top-0 p-4 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-500"
								:style="{ 'background-color': product.color }"
							>
								<h2 class="uppercase text-2xl leading-tight absolute z-10">
									{{ product.title }}<br />
									{{ product.subtitle }}
								</h2>
								<div
									v-if="product.imageColor == 'true'"
									class="w-full h-full absolute left-0 top-0 opacity-50"
									:style="{ backgroundImage: 'url(' + product.color + ')' }"
								></div>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</main>
</template>

<script>
import breadcrumbs from "../breadcrumbs.vue";

export default {
	components: {
		breadcrumbs,
	},
	// flLeft and fldLeft are props that can be passed bua a route to initialize the page with an open filters menu
	props: ["subcategory", "flLeft", "fldLeft"],
	data: function () {
		return {
			info: "",
			products: "",
			hasNextPage: "",
			nextPage: "",
			paginating: false,
			isFiltered: false,
			endpoint: null,
			filtersLeft: true,
			filteredLeft: true,
		};
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	mounted() {
		// Listen to scroll event
		window.addEventListener("scroll", this.handleScroll);
		// Set the endpoint based on the current route
		this.getRouteEndpoint();
		// And then get the products
		this.getProducts(this.endpoint);

		// Wait for filter component to announce the computed uri
		this.$root.$on("filteredUri", (filteredUri) => {
			// Set the endpoint with filter params
			this.endpoint = filteredUri;

			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}

			// And then get the products
			this.getProducts(this.endpoint);
		});

		this.$root.$on("filtered", (filtered) => {
			if (filtered) {
				this.isFiltered = true;
			} else {
				this.isFiltered = false;
			}
		});

		this.$root.$on("filtersState", (filtersState) => {
			if (filtersState == "beforeEnter") {
				this.filtersLeft = false;
				this.filteredLeft = false;
			} else if (filtersState == "beforeLeave") {
				this.filteredLeft = true;
			} else {
				this.filtersLeft = true;
			}
		});

		// if we are openening filters from a non products page
		if (this.$route.params.flLeft == false) {
			this.filteredLeft = false;
			this.filtersLeft = false;
		}
	},
	watch: {
		$route(to, from) {
			// Set the endpoint based on the current route
			this.getRouteEndpoint();
			// And then get the products
			this.getProducts(this.endpoint);
		},
	},
	beforeRouteLeave(to, from, next) {
		this.$root.prodsFilterOpen = false;
		next();
	},
	methods: {
		clearAll: function () {
			this.$root.$emit("filtersCleared", true);
		},
		getRouteEndpoint: function () {
			if (this.$route.meta.isSubSub) {
				let cat = this.$route.params.category;
				let sub = this.$route.params.subcategory;
				let subSub = this.$route.params.subSubcategory;
				this.endpoint = `/products/${cat}/${sub}/${subSub}.json`;
			} else {
				this.endpoint = this.$router.currentRoute.path + ".json";
			}

			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}
		},
		getProducts: function (endpoint) {
			console.log("Fetching products");
			axios
				.get(endpoint)
				.then(
					(response) => (
						(this.info = response.data.info),
						(this.products = response.data.products),
						(this.hasNextPage = response.data.hasNextPage),
						(this.nextPage = response.data.nextPage),
						this.$root.$emit("productsLoaded", true)
					)
				);
		},
		handleScroll(event) {
			var productsListEl = document.getElementById("products-list");
			let totalDistance =
				productsListEl.offsetTop +
				productsListEl.offsetHeight -
				window.innerHeight;
			if (window.scrollY >= totalDistance && this.paginating == false) {
				this.paginating = true;
				console.log("You've scrolled past loaded products");
				this.getMoreProducts(this.nextPage);
			}
		},
		getMoreProducts: function (nextPage) {
			console.log("Fetching more products");
			console.log("nextPage:", nextPage);
			if (nextPage != null) {
				axios
					.get(nextPage)
					.then(
						(response) => (
							// (this.hasNextPage = response.data.hasNextPage),
							// (this.nextPage = response.data.nextPage),
							(this.products = this.products.concat(response.data.products)),
							this.$root.$emit("productsLoaded", true),
							(this.paginating = false)
						)
					);
			}
		},
	},
};
</script>
