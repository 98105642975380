<style lang="scss" scoped>
.swiper-slide {
	$root: &;
	&__overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.25);
		#{$root}-active & {
			background-color: rgba(#000, 0.15);
		}
		h3 {
			width: 100%;
			padding-left: 24px;
			padding-top: 28px;
			white-space: pre-line;
			position: relative;
			z-index: 5;
			@screen xl {
				padding-left: 100px;
				padding-top: 56px;
			}
		}
	}
	figure {
		height: 560px;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(black, 0.15);
			z-index: 1;
		}
		@screen xl {
			height: 700px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.swiper-container {
	position: relative;
}
.button-prev,
.button-next {
	position: absolute;
	top: 50%;
	width: 32px;
	height: 32px;
	margin-top: -16px;
	color: white;
	z-index: 99;
	@apply hidden;
	@apply md:block;
	transition: opacity 0.35s;
}
.button-prev:hover,
.button-next:hover {
}
.button-prev {
	left: 0;
	margin-left: 24px;
	@screen xl {
		margin-left: 100px;
	}
}
.button-next {
	right: 0;
	margin-right: 24px;
	@screen xl {
		margin-right: 100px;
	}
}
</style>
<style lang="scss">
#slider {
	.swiper-pagination-bullets {
		bottom: 36px;
		@screen xl {
			bottom: 46px;
		}
	}
	.swiper-pagination-bullet {
		width: 24px;
		height: 24px;
		background-color: transparent;
		opacity: 1;
		@apply border;
		@apply border-white;
		margin: 0 6px;
	}
	.swiper-pagination-bullet-active {
		@apply bg-white;
	}
}
</style>

<template>
	<div id="slider" class="swiper-container">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="slide in data">
				<a :href="slide.link" class="block relative">
					<figure>
						<img
							:src="slide.image"
							:data-lg="slide.image"
							:data-md="slide.imageMd"
							:data-sm="slide.imageSm"
						/>
					</figure>
					<div class="swiper-slide__overlay">
						<h3
							class="text-5xl leading-tight text-white"
							v-html="slide.title"
						></h3>
					</div>
				</a>
			</div>
		</div>
		<div class="button-prev opacity-0">
			<arrow-left></arrow-left>
		</div>
		<div class="button-next opacity-0">
			<arrow-right></arrow-right>
		</div>
		<div class="swiper-pagination"></div>
	</div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

import arrowLeft from "../components/svg/arrow-left.vue";
import arrowRight from "../components/svg/arrow-right.vue";

export default {
	components: {
		arrowLeft,
		arrowRight,
	},
	props: {
		data: {},
	},
	mounted() {
		var slider = new Swiper("#slider", {
			speed: 500,
			autoplay: {
				delay: 10000,
			},
			slidesPerView: 1,
			navigation: {
				prevEl: "#slider .button-prev",
				nextEl: "#slider .button-next",
			},
			pagination: {
				el: "#slider .swiper-pagination",
				type: "bullets",
			},
			loop: true,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
		});

		let sliderEl = document.getElementById("slider");
		let width = sliderEl.offsetWidth;

		sliderEl.addEventListener("mousemove", (e) => {
			let x = event.clientX;
			let y = event.clientY;
			let leftButtonEl = document.querySelector(".button-prev");
			let rightButtonEl = document.querySelector(".button-next");
			if (x < width / 3) {
				leftButtonEl.classList.add("opacity-100");
			} else {
				leftButtonEl.classList.remove("opacity-100");
			}
			if (x > width - width / 3) {
				rightButtonEl.classList.add("opacity-100");
			} else {
				rightButtonEl.classList.remove("opacity-100");
			}
		});

		function getWindowWidth() {
			let windowWidth = window.innerWidth;
			let slideImages = document.querySelectorAll(".swiper-slide img");
			for (let img of slideImages) {
				if (windowWidth > 1200) {
					img.src = img.dataset.lg;
				} else if (windowWidth > 620) {
					if (img.dataset.md != "") {
						img.src = img.dataset.md;
					}
				} else {
					if (img.dataset.sm != "") {
						img.src = img.dataset.sm;
					}
				}
			}
		}
		getWindowWidth();
		window.addEventListener("resize", (e) => {
			getWindowWidth();
		});
	},
};
</script>
