<style lang="css">
.text-reveal > span {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
}
.text-reveal span > span {
	transform: translateY(100%);
	opacity: 0;
	display: inline-block;
	vertical-align: bottom;
	transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.text-reveal.revealed span > span {
	transform: translateY(0);
	opacity: 1;
}
</style>

<template>
	<p
		v-observe-visibility="{
			callback: visibilityChanged,
			throttle: 300,
			once: true,
		}"
		:class="[isRevealed ? 'revealed' : '', 'text-reveal']"
		v-html="splitText"
	></p>
</template>

<script>
import { parse } from "node-html-parser";

export default {
	props: {
		text: {
			type: String,
			required: true,
			default: "Text",
		},
	},
	data: function () {
		return {
			isRevealed: false,
		};
	},
	methods: {
		visibilityChanged(isVisible, entry) {
			this.isRevealed = isVisible;
		},
	},
	computed: {
		splitText: function () {
			var splitText = "";
			var rootText = parse(this.text);
			for (let node of rootText.childNodes) {
				if (node.nodeType === 3) {
					let text = node._rawText.replace(
						/([^\s()[\]{}"]+)/g,
						"<span><span>$1</span></span>"
					);
					splitText += text;
				} else if (node.nodeType === 1 && node.rawTagName === "a") {
					splitText += `<span><span><a ${node.rawAttrs}>${node.textContent}</a></span></span>`;
				} else if (node.nodeType === 1 && node.rawTagName === "br") {
					splitText += `<br>`;
				}
			}
			return splitText;
		},
	},
};
</script>
