<style lang="scss" scoped>
li a {
	position: relative;
}
li a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.45s;
	transform-origin: right;
}
li a:hover::after {
	transform: scale(1);
	transform-origin: left;
}

li a.router-link-exact-active::after {
	transform: scale(1);
}

ul li ul li ul {
	padding-left: 10px;
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity 0;
}

ul li ul li:hover ul {
	opacity: 1;
	height: auto;
	transition: opacity 1s;
}

.category {
	transition: width 0.65s;
}

.category-preview {
	overflow: hidden;
	@media (max-width: 1439px) {
		display: none !important;
	}
}
</style>

<template>
	<transition name="slide-down">
		<div
			id="products-menu-desktop"
			v-if="$root.prodMenuDtOpen"
			class="hidden fixed bg-grey w-full h-full-header top-header z-30 overflow-hidden md:block"
		>
			<ul
				class="px-8 lg:inner-px py-8 -mx-4 flex flex-row overflow-scroll h-full"
			>
				<li class="px-4 whitespace-no-wrap">
					<router-link
						@click.native="$parent.prodMenuDtOpen = false"
						to="/products"
						class="uppercase font-bold text-xs inline-block"
					>
						{{ $t("links.allProducts") }}
					</router-link>
				</li>
				<li
					v-for="category in categories"
					:class="[
						category.isHovered ? 'w-56 xl:w-2/3' : 'w-56',
						'px-4 category flex box-content min-w-56',
					]"
				>
					<div class="whitespace-no-wrap">
						<router-link
							@click.native="$parent.prodMenuDtOpen = false"
							:to="'/' + category.uri"
							class="uppercase font-bold text-xs inline-block mb-4"
						>
							{{ category.title }}
						</router-link>
						<template v-if="category.subcategories">
							<ul
								@mouseenter="category.isHovered = true"
								@mouseleave="category.isHovered = false"
							>
								<li
									v-for="subcategory in category.subcategories"
									@mouseenter="showBySubCategoryIndex = subcategory"
									@mouseleave="showBySubCategoryIndex = null"
									:key="subcategory.index"
								>
									<router-link
										@click.native="$parent.prodMenuDtOpen = false"
										:to="'/' + subcategory.uri"
										class="uppercase text-xs mb-2 inline-block"
									>
										{{ subcategory.title }}
									</router-link>
									<ul v-if="subcategory.subSubcategories.length">
										<li
											v-for="subSubcategory in subcategory.subSubcategories"
											@mouseenter="showBySubSubCategoryIndex = subSubcategory"
											@mouseleave="showBySubSubCategoryIndex = null"
											:key="subSubcategory.index"
										>
											<router-link
												@click.native="$parent.prodMenuDtOpen = false"
												:to="'/' + subSubcategory.transformedUri"
												class="uppercase text-xs mb-2 inline-block"
											>
												{{ subSubcategory.title }}
											</router-link>
										</li>
									</ul>
								</li>
							</ul>
						</template>
					</div>
					<div class="category-preview w-full h-full">
						<transition name="fade-w-delay">
							<ul
								v-if="category.isHovered"
								class="h-full mx-auto px-4"
								style="max-width: 32rem"
							>
								<li
									v-for="subcategory in category.subcategories"
									v-if="
										showBySubCategoryIndex === subcategory &&
										showBySubSubCategoryIndex === null
									"
									class="text-3xl leading-tight h-full flex flex-col"
								>
									<div class="mt-8 mb-5">
										<text-reveal :text="subcategory.description"></text-reveal>
									</div>
									<div class="flex-grow">
										<image-reveal
											:imageSrc="subcategory.previewImage"
											bgColor="bg-grey"
										></image-reveal>
									</div>
								</li>

								<template v-for="subcategory in category.subcategories">
									<transition name="fade" appear>
										<li
											v-for="subsubcategory in subcategory.subSubcategories"
											v-if="
												showBySubCategoryIndex === subcategory &&
												showBySubSubCategoryIndex === subsubcategory
											"
											class="text-3xl leading-tight h-full flex flex-col"
										>
											<div class="mt-8 mb-5">
												<text-reveal
													:text="subsubcategory.description"
												></text-reveal>
											</div>
											<div class="flex-grow">
												<image-reveal
													:imageSrc="subsubcategory.previewImage"
													bgColor="bg-grey"
												></image-reveal>
											</div>
										</li>
									</transition>
								</template>
							</ul>
						</transition>
					</div>
				</li>
			</ul>
		</div>
	</transition>
</template>

<script>
export default {
	data: function () {
		return {
			showBySubCategoryIndex: null,
			showBySubSubCategoryIndex: null,
			categories: null,
			endpoint: "/products-menu.json",
		};
	},
	mounted() {
		if (this.$root.language == "el") {
			this.endpoint = "/el/products-menu.json";
		}
		axios
			.get(this.endpoint)
			.then((response) => (this.categories = response.data.categories));
	},
};
</script>
