<style lang="scss" scoped>
button {
	position: relative;
}
button::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.15rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
button:hover::after {
	transform: scale(1);
	transform-origin: left;
}
</style>

<template>
	<main class="mt-header min-h-full-header overflow-hidden">
		<breadcrumbs :info="info"></breadcrumbs>

		<transition name="change-category" mode="out-in">
			<div>
				<ul id="articles-list" class="flex flex-wrap pt-4 pb-20 inner-px -mx-1">
					<li
						v-for="article in articles"
						:key="article.uri"
						class="w-full md:w-1/3 px-1 mb-12"
					>
						<router-link :to="article.uri">
							<div class="relative">
								<div class="mb-5">
									<image-reveal
										:imageSrc="article.thumbnail"
										bgColor="bg-white"
									></image-reveal>
								</div>
								<div
									class="absolute left-0 top-0 p-4 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-500 z-1"
									:style="{ 'background-color': article.color }"
								></div>
							</div>
							<h2 class="uppercase text-2xl leading-tight mb-8">
								<text-reveal :text="article.title"></text-reveal>
							</h2>
							<p>{{ article.excerpt }}</p>
						</router-link>
					</li>
				</ul>
				<button
					v-if="hasNextPage"
					class="uppercase font-bold text-sm mb-24 block mx-auto"
					@click="getMoreArticles(nextPage)"
				>
					{{ $t("buttons.showMorePosts") }}
				</button>
			</div>
		</transition>
	</main>
</template>

<script>
import breadcrumbs from "../breadcrumbs.vue";

export default {
	components: {
		breadcrumbs,
	},
	props: ["subcategory"],
	data: function () {
		return {
			info: "",
			articles: "",
			hasNextPage: "",
			nextPage: "",
			isFiltered: false,
			endpoint: null,
		};
	},
	mounted() {
		// Set the endpoint based on the current route
		this.getRouteEndpoint();
		// And then get the article
		this.getArticles(this.endpoint);
	},
	methods: {
		getRouteEndpoint: function () {
			this.endpoint = this.$router.currentRoute.path + ".json";

			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}
		},
		getArticles: function (endpoint) {
			console.log("Fetching articles");
			axios
				.get(endpoint)
				.then(
					(response) => (
						(this.info = response.data.info),
						(this.articles = response.data.articles),
						(this.hasNextPage = response.data.hasNextPage),
						(this.nextPage = response.data.nextPage)
					)
				);
		},
		getMoreArticles: function (nextPage) {
			console.log("Fetching more articles");
			axios
				.get(nextPage)
				.then(
					(response) => (
						(this.hasNextPage = response.data.hasNextPage),
						(this.nextPage = response.data.nextPage),
						(this.articles = this.articles.concat(response.data.articles))
					)
				);
		},
	},
};
</script>
