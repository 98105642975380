<style lang="css" scoped>
.iframe-reveal {
	position: relative;
}
.iframe-reveal > div {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	transition: height 0.65s;
	@apply z-10;
}
.iframe-reveal.revealed > div {
	height: 0;
}
iframe {
	width: 100%;
	object-fit: cover;
}
</style>
<template>
	<figure
		v-observe-visibility="{
			callback: visibilityChanged,
			throttle: 300,
			once: true,
		}"
		:class="[isRevealed ? 'revealed' : '', 'iframe-reveal']"
	>
		<iframe width="1280" height="720" :src="src" frameborder="0"></iframe>
		<div :class="bgColor"></div>
	</figure>
</template>
<script>
export default {
	props: {
		src: {},
		bgColor: {},
	},
	data: function () {
		return {
			isRevealed: false,
		};
	},
	methods: {
		visibilityChanged(isVisible, entry) {
			this.isRevealed = isVisible;
		},
	},
};
</script>
