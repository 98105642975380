<style lang="css" scoped>
nav {
	@apply text-black;
}
li a,
li button {
	position: relative;
}
li a::after,
li button::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.25s;
	transform-origin: right;
}
li a:hover::after,
li button:hover::after {
	transform: scale(1);
	transform-origin: left;
}

li a.router-link-active::after {
	transform: scale(1);
}
</style>

<template>
	<transition name="slide-right">
		<nav
			id="page-navigation-mobile"
			v-if="$root.navMbOpen"
			class="inner-pl-mobile bg-grey fixed w-full h-full-header top-header left-0 pr-12 pt-8 pb-12 md:hidden flex flex-col justify-between h-full"
		>
			<ul class="flex flex-col space-y-5">
				<li v-for="item in data.items">
					<template v-if="item.uri == 'products'">
						<a
							@click="$root.prodMenuMbOpen = true"
							:class="{ 'router-link-active': routeIsProducts }"
							class="uppercase font-bold text-2xl cursor-pointer"
						>
							{{ item.title }}
						</a>
					</template>
					<template v-else>
						<router-link
							@click.native="$parent.navMbOpen = false"
							:to="'/' + item.uri"
							class="uppercase font-bold text-2xl"
						>
							{{ item.title }}
						</router-link>
					</template>
				</li>
			</ul>
			<div>
				<h3 class="uppercase font-bold mb-3">Follow us</h3>
				<ul class="flex space-x-5">
					<li>
						<a :href="data.social.facebook" target="_blank" rel="noopener"
							><icon-fb></icon-fb
						></a>
					</li>
					<li>
						<a :href="data.social.instagram" target="_blank" rel="noopener"
							><icon-ig></icon-ig
						></a>
					</li>
					<li>
						<a :href="data.social.youtube" target="_blank" rel="noopener"
							><icon-yt></icon-yt
						></a>
					</li>
					<li>
						<a :href="data.social.linkedin" target="_blank" rel="noopener"
							><icon-li></icon-li
						></a>
					</li>
				</ul>
			</div>
		</nav>
	</transition>
</template>

<script>
export default {
	props: {
		data: {},
	},
	computed: {
		routeIsProducts: function () {
			return this.$route.matched.some(({ name }) =>
				/products|subcategory$/.test(name)
			);
		},
	},
};
</script>
