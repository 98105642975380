<style lang="scss" scoped>
/*Img placeholder*/
figure {
	width: 100%;
	height: 0;
	padding-bottom: 100%;
}
figure img {
	opacity: 1;
	@apply transition-opacity;
	@apply duration-300;
}
figure img[data-src] {
	opacity: 0;
}
/*Swiper*/
.button-prev,
.button-next {
	position: absolute;
	top: 50%;
	width: 32px;
	height: 32px;
	margin-top: -16px;
	/*background-color: red;*/
	z-index: 99;
}
.button-prev:hover,
.button-next:hover {
}
.button-prev {
	left: 1.25rem;
	transition: transform 0.35s;
}
.button-next {
	right: 1.25rem;
	transition: transform 0.35s;
}
.button-prev.swiper-button-disabled {
	opacity: 0.2;
	transform: translateX(-140%);
}
.button-next.swiper-button-disabled {
	opacity: 0.2;
	transform: translateX(140%);
}
.pagination {
	width: 100%;
	height: 1px !important;
	background-color: #eee;
	z-index: 99;
}
.swiper-slide {
	width: 100%;
	@screen sm {
		width: 50%;
	}
	@screen md {
		width: 33.33%;
	}
	@screen lg {
		width: 25%;
	}
}
</style>
<style>
#similar-products-slider .pagination-progressbar-fill {
	background-color: black;
	height: 1px !important;
	transform-origin: left;
}
</style>

<template>
	<div>
		<h3 class="uppercase text-xs font-bold mb-8 pl-5 pr-5">
			{{ $t("labels.similarProducts") }}
		</h3>

		<div id="similar-products-slider" class="swiper-container">
			<div class="pagination"></div>
			<div class="swiper-wrapper">
				<div
					class="swiper-slide w-1/2 md:w-1/4"
					v-for="similarProduct in similarProducts"
				>
					<router-link :to="similarProduct.uri">
						<figure>
							<img v-onload="similarProduct.thumbnail" alt="" />
						</figure>
						<div
							class="absolute left-0 top-0 p-4 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-500"
							:style="{ 'background-color': similarProduct.color }"
						>
							<h2 class="uppercase text-2xl leading-tight">
								{{ similarProduct.title }}<br />
								{{ similarProduct.subtitle }}
							</h2>
						</div>
					</router-link>
				</div>
			</div>

			<div class="button-prev">
				<arrow-left></arrow-left>
			</div>
			<div class="button-next">
				<arrow-right></arrow-right>
			</div>
		</div>
	</div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

import arrowLeft from "../components/svg/arrow-left.vue";
import arrowRight from "../components/svg/arrow-right.vue";

export default {
	components: {
		arrowLeft,
		arrowRight,
	},
	props: {
		similarProducts: {},
	},
	updated() {
		console.log("updated");
		var similarProducts = new Swiper("#similar-products-slider", {
			speed: 500,
			pagination: {
				el: ".pagination",
				clickable: false,
				type: "progressbar",
				renderProgressbar: function (progressbarFillClass) {
					return '<div class="' + progressbarFillClass + '"></div>';
				},
				progressbarFillClass: "pagination-progressbar-fill",
			},
			navigation: {
				prevEl: "#similar-products-slider .button-prev",
				nextEl: "#similar-products-slider .button-next",
			},
			slidesPerView: "auto",
			loop: false,
			// simulateTouch: false
		});
	},
};
</script>
