<style lang="scss" scoped>
/*Links*/
ul li a {
	position: relative;
	user-select: none;
}
ul li a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
ul li a:hover::after {
	transform: scale(1);
	transform-origin: left;
}
ul li a.is-active::after {
	transform: scale(1);
}

/*Tab gallery styles*/
ul li figure {
	height: 100%;
}
ul li img {
	width: 100%;
}
ul li.half {
	@apply w-full;
}
@screen sm {
	ul li.half {
		@apply w-1/2;
	}
}
ul li.small {
	@apply w-full;
}
@screen sm {
	ul li.small {
		@apply w-1/3;
	}
}
ul li.medium {
	@apply w-full;
}
@screen sm {
	ul li.medium {
		@apply w-2/3;
	}
	ul li.medium {
		@apply w-2/3;
	}
}
ul li.large {
	@apply w-full;
}

.feature__toggle button {
	position: relative;
	user-select: none;
	&::after {
		content: "";
		width: 100%;
		transform: scale(0);
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -0.3rem;
		@apply bg-white;
		transition: transform 0.25s, background-color 1s;
		transform-origin: right;
	}
	&:hover::after {
		transform: scale(1);
		transform-origin: left;
	}
	&.is-active::after {
		transform: scale(1);
	}
}

svg {
	position: relative;
	z-index: 1;
}

.group:hover .animation-swipe {
	animation-play-state: running;
}
.animation-swipe {
	animation-name: swipe;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}
@keyframes swipe {
	0% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(-5px);
	}
}
</style>

<template>
	<div class="feature">
		<div class="bg-black text-white flex flex-wrap inner-px py-16 -mx-5">
			<div class="px-5 pt-8 pb-5 md:pb-12 w-full md:w-2/5">
				<h3 class="text-5xl leading-tight whitespace-pre-line">
					<text-reveal :text="title"></text-reveal>
				</h3>
			</div>
			<div
				class="w-full md:w-3/5 px-5 pt-8 pb-5 md:pb-12 flex flex-col items-start"
			>
				<div class="text-2xl d-flex flex-col space-y-5 mb-4">
					<div v-for="text in texts" class="mb-3">
						<text-reveal :text="text.content"></text-reveal>
					</div>
				</div>
				<div
					class="feature__toggle flex items-center text-white group mt-5"
					:class="!expanded ? 'visible' : 'invisible'"
				>
					<button type="button" class="uppercase mr-5" @click="expanded = true">
						{{ toggle }}
					</button>
					<arrow-alt-right
						class="opacity-0 group-hover:opacity-100 transition-opacity duration-300 animation-swipe"
					></arrow-alt-right>
				</div>
			</div>
		</div>
		<div
			id="feature-box"
			:class="expanded ? 'block' : 'h-0'"
			class="inner-px bg-grey"
		>
			<div class="py-16">
				<div class="relative relative" :class="expanded ? 'block' : 'hidden'">
					<div class="flex flex-wrap -mx-5">
						<div class="px-5 w-full md:w-2/5 mb-8 md:mb-0">
							<h4 class="text-5xl leading-tight whitespace-pre-line">
								<text-reveal :text="title2"></text-reveal>
							</h4>
						</div>
						<div id="tabs" class="px-5 w-full md:w-3/5">
							<!-- Tab list -->
							<div class="-mx-5 pb-10">
								<ul
									class="w-full md:w-3/5 px-5 uppercase flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8"
								>
									<li v-for="tab in tabs">
										<a
											:class="{ 'is-active': showTabPanel === tab.name }"
											class="cursor-pointer"
											style="white-space: nowrap"
											@click="showTabPanel = tab.name"
											>{{ tab.name }}</a
										>
									</li>
								</ul>
								<button
									type="button"
									@click="expanded = false"
									class="absolute right-0 top-0"
								>
									<close></close>
								</button>
							</div>
							<!-- Tab text panels -->
							<div class="w-full pt-1 pb-4" style="min-height: 100px">
								<transition name="" mode="out-in">
									<template
										v-for="tab in tabs"
										v-if="showTabPanel === tab.name"
									>
										<div :key="tab.name">
											<h5 v-html="tab.title" class="text-2xl mb-8"></h5>
											<div v-html="tab.text" class="space-y-3"></div>
										</div>
									</template>
								</transition>
							</div>
						</div>
					</div>
					<div class="flex gallery-panels -inner-px">
						<!-- Tab gallery panels -->
						<transition name="fade" mode="out-in">
							<template v-for="tab in tabs" v-if="showTabPanel === tab.name">
								<ul class="flex flex-wrap flex-1 -mx-3 gallery">
									<li
										v-for="file in tab.gallery"
										class="px-1 mb-2"
										:class="file.style"
									>
										<image-reveal
											v-if="file.type == 'image'"
											:imageSrc="file.url"
											bgColor="bg-white"
										></image-reveal>
										<video-reveal
											v-if="file.type == 'video'"
											:videoSrc="file.url"
											bgColor="bg-white"
										></video-reveal>
										<iframe-reveal
											v-if="file.type == 'embed'"
											:src="file.url"
											bgColor="bg-white"
										></iframe-reveal>
									</li>
								</ul>
							</template>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import arrowAltRight from "./svg/arrow-alt-right.vue";
import close from "./svg/close.vue";

export default {
	components: {
		arrowAltRight,
		close,
	},
	data: function () {
		return {
			title: "",
			toggle: "",
			title2: "",
			texts: "",
			tabs: "",
			showTabPanel: null,
			expanded: false,
		};
	},
	mounted() {
		this.endpoint = this.$router.currentRoute.path + "home.json";
		// If language is el prepend language segment to the endpoint
		if (this.$root.language == "el") {
			this.endpoint = "/el" + this.endpoint;
		}
		axios
			.get(this.endpoint)
			.then(
				(response) => (
					(this.title = response.data.feature.title),
					(this.toggle = response.data.feature.toggle),
					(this.title2 = response.data.feature.title2),
					(this.texts = response.data.feature.texts),
					(this.tabs = response.data.feature.tabs),
					(this.showTabPanel = this.tabs[0].name)
				)
			);
	},
	watch: {
		expanded: function () {
			if (this.expanded == true) {
				const id = "feature-box";
				const yOffset = -70;
				const element = document.getElementById(id);
				const y =
					element.getBoundingClientRect().top + window.pageYOffset + yOffset;

				window.scrollTo({ top: y, behavior: "smooth" });
			}
		},
	},
};
</script>
