var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-down"}},[(_vm.$root.prodMenuDtOpen)?_c('div',{staticClass:"hidden fixed bg-grey w-full h-full-header top-header z-30 overflow-hidden md:block",attrs:{"id":"products-menu-desktop"}},[_c('ul',{staticClass:"px-8 lg:inner-px py-8 -mx-4 flex flex-row overflow-scroll h-full"},[_c('li',{staticClass:"px-4 whitespace-no-wrap"},[_c('router-link',{staticClass:"uppercase font-bold text-xs inline-block",attrs:{"to":"/products"},nativeOn:{"click":function($event){_vm.$parent.prodMenuDtOpen = false}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("links.allProducts"))+"\n\t\t\t\t")])],1),_vm._v(" "),_vm._l((_vm.categories),function(category){return _c('li',{class:[
					category.isHovered ? 'w-56 xl:w-2/3' : 'w-56',
					'px-4 category flex box-content min-w-56' ]},[_c('div',{staticClass:"whitespace-no-wrap"},[_c('router-link',{staticClass:"uppercase font-bold text-xs inline-block mb-4",attrs:{"to":'/' + category.uri},nativeOn:{"click":function($event){_vm.$parent.prodMenuDtOpen = false}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(category.title)+"\n\t\t\t\t\t")]),_vm._v(" "),(category.subcategories)?[_c('ul',{on:{"mouseenter":function($event){category.isHovered = true},"mouseleave":function($event){category.isHovered = false}}},_vm._l((category.subcategories),function(subcategory){return _c('li',{key:subcategory.index,on:{"mouseenter":function($event){_vm.showBySubCategoryIndex = subcategory},"mouseleave":function($event){_vm.showBySubCategoryIndex = null}}},[_c('router-link',{staticClass:"uppercase text-xs mb-2 inline-block",attrs:{"to":'/' + subcategory.uri},nativeOn:{"click":function($event){_vm.$parent.prodMenuDtOpen = false}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(subcategory.title)+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),(subcategory.subSubcategories.length)?_c('ul',_vm._l((subcategory.subSubcategories),function(subSubcategory){return _c('li',{key:subSubcategory.index,on:{"mouseenter":function($event){_vm.showBySubSubCategoryIndex = subSubcategory},"mouseleave":function($event){_vm.showBySubSubCategoryIndex = null}}},[_c('router-link',{staticClass:"uppercase text-xs mb-2 inline-block",attrs:{"to":'/' + subSubcategory.transformedUri},nativeOn:{"click":function($event){_vm.$parent.prodMenuDtOpen = false}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(subSubcategory.title)+"\n\t\t\t\t\t\t\t\t\t\t")])],1)}),0):_vm._e()],1)}),0)]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"category-preview w-full h-full"},[_c('transition',{attrs:{"name":"fade-w-delay"}},[(category.isHovered)?_c('ul',{staticClass:"h-full mx-auto px-4",staticStyle:{"max-width":"32rem"}},[_vm._l((category.subcategories),function(subcategory){return (
									_vm.showBySubCategoryIndex === subcategory &&
									_vm.showBySubSubCategoryIndex === null
								)?_c('li',{staticClass:"text-3xl leading-tight h-full flex flex-col"},[_c('div',{staticClass:"mt-8 mb-5"},[_c('text-reveal',{attrs:{"text":subcategory.description}})],1),_vm._v(" "),_c('div',{staticClass:"flex-grow"},[_c('image-reveal',{attrs:{"imageSrc":subcategory.previewImage,"bgColor":"bg-grey"}})],1)]):_vm._e()}),_vm._v(" "),_vm._l((category.subcategories),function(subcategory){return [_c('transition',{attrs:{"name":"fade","appear":""}},_vm._l((subcategory.subSubcategories),function(subsubcategory){return (
											_vm.showBySubCategoryIndex === subcategory &&
											_vm.showBySubSubCategoryIndex === subsubcategory
										)?_c('li',{staticClass:"text-3xl leading-tight h-full flex flex-col"},[_c('div',{staticClass:"mt-8 mb-5"},[_c('text-reveal',{attrs:{"text":subsubcategory.description}})],1),_vm._v(" "),_c('div',{staticClass:"flex-grow"},[_c('image-reveal',{attrs:{"imageSrc":subsubcategory.previewImage,"bgColor":"bg-grey"}})],1)]):_vm._e()}),0)]})],2):_vm._e()])],1)])})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }