<style lang="css" scoped></style>

<template>
	<main class="mt-header min-h-full-header overflow-hidden">
		<component v-bind:is="sectionType" :data="sectionData"></component>
		<feature></feature>
		<feed></feed>
		<div class="bg-black py-12 flex justify-center">
			<c-button
				link="/sponsorships-and-collaborations"
				:text="sponsorshipsButton"
				:negative="true"
				type="router-link"
			></c-button>
		</div>
	</main>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

import slider from "../slider.vue";
import boxes from "../boxes.vue";
import feature from "../feature.vue";
import feed from "../feed.vue";
import cButton from "../c-button.vue";

export default {
	components: {
		boxes,
		slider,
		feature,
		feed,
		cButton,
	},
	data: function () {
		return {
			sectionType: "",
			sectionData: "",
			sponsorshipsButton: "",
		};
	},
	mounted() {
		this.endpoint = this.$router.currentRoute.path + "home.json";
		// If language is el prepend language segment to the endpoint
		if (this.$parent.language == "el") {
			this.endpoint = "/el" + this.endpoint;
		}
		axios.get(this.endpoint).then((response) => {
			this.sponsorshipsButton = response.data.sponsorshipsButton;
			if (response.data.boxes) {
				this.sectionType = "boxes";
				this.sectionData = response.data.boxes;
			}
			if (response.data.slides) {
				this.sectionType = "slider";
				this.sectionData = response.data.slides;
			}
		});
	},
	updated() {
		var homepageSlider = new Swiper("#homepage-slider", {
			speed: 500,
			navigation: {
				prevEl: "#homepage-slider .button-prev",
				nextEl: "#homepage-slider .button-next",
			},
			loop: true,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
		});
	},
};
</script>
