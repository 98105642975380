<style lang="scss" scoped>
ul li:not(:last-child)::after {
	content: ">";
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
</style>

<template>
	<ul class="inner-px py-5 flex flex-wrap uppercase text-xs">
		<li v-if="info.grandparent">
			<router-link :to="'/' + info.grandparent.url">
				{{ info.grandparent.title }}
			</router-link>
		</li>
		<li v-if="info.category">
			<router-link :to="'/' + info.category.url">{{
				categoryTitle
			}}</router-link>
		</li>
		<li v-if="info.subcategory">
			<router-link :to="'/' + info.subcategory.url">{{
				info.subcategory.title
			}}</router-link>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		info: {},
	},
	computed: {
		categoryTitle: function () {
			var categoryTitle = this.info.category.title;
			if (categoryTitle === "all") {
				categoryTitle = this.$t("links.allProducts");
			}
			return categoryTitle;
		},
	},
};
</script>
