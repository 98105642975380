<style lang="css" scoped>
button {
	position: relative;
}
button::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.15rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
button:hover::after {
	transform: scale(1);
	transform-origin: left;
}
</style>

<template>
	<button class="flex uppercase text-xs font-bold">
		{{ text }}
	</button>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
			default: "Text",
		},
	},
	data: function () {
		return {};
	},
};
</script>
