<style lang="css" scoped>
.hero-image {
	width: 100%;
	height: auto;
}

.hero-image img {
	position: relative;
	width: 100%;
	height: auto;
	opacity: 1;
	@apply transition-opacity;
	@apply duration-300;
}

@screen md {
	.hero-image {
		height: calc(100vh - 128px);
		position: relative;
		overflow: hidden;
	}

	.hero-image img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.hero-image img[data-src] {
	opacity: 0;
}

.link {
	position: relative;
}
.link::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
.link:hover::after {
	transform: scale(1);
	transform-origin: left;
}

.share-label {
	position: relative;
}
.share-label::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 1px;
	@apply bg-black;
	transition: width 0.85s;
}
.share-label.revealed::before {
	width: 110px;
}

/*Tab gallery styles*/
ul li figure {
	height: 100%;
}
ul li img {
	width: 100%;
}
ul li.half {
	@apply w-full;
}
@screen sm {
	ul li.half {
		@apply w-1/2;
	}
}
ul li.small {
	@apply w-full;
}
@screen sm {
	ul li.small {
		@apply w-1/3;
	}
}
ul li.medium {
	@apply w-full;
}
@screen sm {
	ul li.medium {
		@apply w-2/3;
	}
	ul li.medium {
		@apply w-2/3;
	}
}
ul li.large {
	@apply w-full;
}

.group:hover .animation-swipe {
	animation-play-state: running;
}
.animation-swipe {
	animation-name: swipe;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}
@keyframes swipe {
	0% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(-5px);
	}
}
</style>
<style>
.gallery img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.article-text p {
	@apply mb-8;
}
.article-text p a::after {
	transform: scale(1);
}

.article-text p a:hover::after {
	transform: scale(0);
}
</style>

<template>
	<main class="mt-header min-h-full-header overflow-hidden">
		<breadcrumbs :info="info"></breadcrumbs>
		<div :key="this.articleId">
			<!-- Cover image -->
			<div class="w-full">
				<figure class="hero-image">
					<img v-onload="article.cover" alt="" />
				</figure>
			</div>

			<div class="flex flex-wrap inner-px py-5 -mx-5">
				<div class="w-full pt-10 px-5 md:w-2/5">
					<div v-if="this.loaded === true">
						<h1 class="text-5xl leading-tight mb-6">
							<text-reveal :text="article.title"></text-reveal>
						</h1>
					</div>
				</div>
				<div class="w-full pt-10 px-5 md:w-3/5">
					<div v-if="this.loaded === true">
						<h3
							v-if="article.subtitle"
							class="uppercase text-2xl leading-tight mb-6"
						>
							<text-reveal :text="article.subtitle"></text-reveal>
						</h3>
						<div class="mb-6 text-2xl">
							<text-reveal :text="article.introText"></text-reveal>
						</div>
						<div class="article-text" v-html="article.text"></div>
					</div>
				</div>
				<div
					v-if="article.collaborators"
					class="pt-12 w-full px-5 md:w-3/5 md:ml-auto"
				>
					<div class="flex">
						<div class="w-2/5 md:w-1/6 flex-shrink-0" style="min-width: 150px">
							<h3 class="text-xs uppercase font-bold mb-5">
								{{ $t("labels.collaborators") }}:
							</h3>
						</div>
						<div class="w-full">
							<ul class="uppercase text-xs flex flex-wrap -mx-3">
								<li
									v-for="collaborator in article.collaborators"
									class="px-3 mb-4"
								>
									<a
										class="link"
										:href="collaborator.link"
										target="_blank"
										rel="noopener"
										>{{ collaborator.title }}</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div
					v-if="article.sprays"
					class="pt-12 px-5 w-full md:w-3/5 md:ml-auto"
				>
					<div class="flex">
						<div class="w-2/5 md:w-1/6 flex-shrink-0" style="min-width: 150px">
							<h3 class="text-xs uppercase font-bold mb-5">
								{{ $t("labels.sprayInUse") }}:
							</h3>
						</div>
						<div class="w-full">
							<ul class="uppercase text-xs flex flex-wrap -mx-3">
								<li v-for="spray in article.sprays" class="px-3 mb-4">
									<a class="link" :href="'/' + spray.link">{{ spray.title }}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="w-full md:w-3/5 pt-8 px-5 md:ml-auto">
					<div class="flex">
						<div class="w-2/5 md:w-1/6 flex-shrink-0" style="min-width: 150px">
							<div
								class="share-label inline-block font-bold uppercase text-xs mr-6 py-3"
								v-observe-visibility="{
									callback: visibilityChanged,
									throttle: 300,
									once: true,
								}"
							>
								{{ $t("labels.shareOn") }}:
							</div>
						</div>
						<div class="w-5/6">
							<ul class="space-x-6 flex mt-3">
								<li class="wiggle">
									<a :href="article.fbShareUrl" target="_blank" rel="noopener"
										><icon-fb></icon-fb
									></a>
								</li>
								<li class="wiggle">
									<a :href="article.ttShareUrl" target="_blank" rel="noopener"
										><icon-tt></icon-tt
									></a>
								</li>
								<li class="wiggle">
									<a :href="article.liShareUrl" target="_blank" rel="noopener"
										><icon-li></icon-li
									></a>
								</li>
								<li class="wiggle">
									<icon-pt></icon-pt>
								</li>
								<li class="wiggle">
									<a :href="article.mlShareUrl" target="_blank" rel="noopener"
										><icon-ml></icon-ml
									></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="py-5">
				<ul class="flex flex-wrap -mx-3 gallery">
					<li
						v-for="file in article.gallery"
						class="px-1 mb-2"
						:class="file.style"
					>
						<image-reveal
							v-if="file.type == 'image'"
							:imageSrc="file.url"
							bgColor="bg-white"
						></image-reveal>
						<video-reveal
							v-if="file.type == 'video'"
							:videoSrc="file.url"
							bgColor="bg-white"
						></video-reveal>
						<iframe-reveal
							v-if="file.type == 'embed'"
							:src="file.url"
							bgColor="bg-white"
						></iframe-reveal>
					</li>
				</ul>
			</div>

			<div class="pt-5 pb-16 md:pt-8 pb-24">
				<div class="inner-px">
					<ul
						class="flex flex-col md:flex-row flex-wrap justify-between text-center uppercase text-2xl"
					>
						<li
							class="mx-auto md:w-1/2 flex items-center group justify-center mb-12 md:justify-start md:mb-0 leading-none relative"
						>
							<arrow-alt-left
								v-if="article.prevLink"
								class="absolute top-0 -mt-1 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 animation-swipe"
								style="transform: scale(-1)"
							></arrow-alt-left>
							<a
								v-if="article.prevLink"
								:href="article.prevLink"
								class="mx-16 link"
								>{{ $t("links.previous") }}</a
							>
							<span v-else class="text-grey">{{ $t("links.previous") }}</span>
						</li>

						<li
							class="mx-auto md:w-1/2 flex items-center group justify-center md:justify-end leading-none relative"
						>
							<a
								v-if="article.nextLink"
								:href="article.nextLink"
								class="mx-16 link"
								>{{ $t("links.next") }}</a
							>
							<span v-else class="text-grey">{{ $t("links.next") }}</span>
							<arrow-alt-right
								v-if="article.nextLink"
								class="absolute top-0 -mt-1 right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 animation-swipe"
							></arrow-alt-right>
						</li>
					</ul>
				</div>

				<div class="px-5 pt-12 flex justify-center">
					<router-link
						to="/sponsorships-and-collaborations"
						class="block font-bold uppercase link text-xs leading-none"
					>
						{{ $t("links.backToSponsorships") }}
					</router-link>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import arrowAltLeft from "../svg/arrow-alt-left.vue";
import arrowAltRight from "../svg/arrow-alt-right.vue";

import breadcrumbs from "../breadcrumbs.vue";

export default {
	components: {
		arrowAltLeft,
		arrowAltRight,
		breadcrumbs,
	},
	props: {
		articleId: {},
	},
	data: function () {
		return {
			info: "",
			article: "",
			endpoint: null,
			loaded: false,
		};
	},
	mounted() {
		this.getArticle();
	},
	methods: {
		getArticle: function () {
			this.loaded = false;
			console.log("Fetching article");
			if (this.$parent.language == "en") {
				console.log("en");
				this.endpoint = this.$router.currentRoute.path + ".json";
			} else {
				console.log("el");
				this.endpoint = "/el" + this.$router.currentRoute.path + ".json";
			}
			axios
				.get(this.endpoint)
				.then(
					(response) => (
						(this.info = response.data.info),
						(this.article = response.data),
						(this.loaded = true)
					)
				);
		},
		visibilityChanged(isVisible, entry) {
			if (entry.isIntersecting === true) {
				entry.target.classList.add("revealed");
			}
		},
	},
};
</script>
