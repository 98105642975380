<style lang="scss" scoped>
.input {
	position: relative;
	@apply py-2;
	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: white;
		transition: width 0.25s;
	}
}
.message {
	padding-top: 5px;
	padding-bottom: 5px;
}
.submit {
	-webkit-appearance: none;
	background-color: transparent;
	cursor: pointer;
	padding: 6px 0;
}
</style>

<template>
	<transition>
		<div v-show-slide:160:ease-in="$root.nlFormOpen">
			<div class="px-12 pt-12 pb-16 bg-black text-white">
				<div class="flex flex-wrap -mx-5">
					<div class="w-full md:w-1/2 lg:w-1/4 px-5 text-xs">
						<h3 class="uppercase font-bold mb-3">Newsletter</h3>
						<p>Subscribe to our newsletter to hear all about our news.</p>
					</div>
					<div class="w-full md:w-1/2 lg:w-1/4 px-5 uppercase text-xs">
						<h3 class="uppercase font-bold mb-3">GDPR compliance</h3>
						<p>
							By signing up, you agree to the
							<a href="" class="font-bold">privacy policy</a> and you will be
							receiving our newsletters.
						</p>
						<p class="mb-12">
							You can withdraw your consent by contacting us or by clicking on
							the “unsubscribe” link at the end of each newsletter.
						</p>
						<div class="mb-12 lg:mb-0">
							<c-checkbox
								label="I agree"
								id="consent"
								v-model="consent"
							></c-checkbox>
						</div>
					</div>
					<div class="w-full md:w-1/2 lg:w-1/2 px-5">
						<form @submit.prevent="check" ref="form" class="h-full">
							<div class="flex flex-wrap md:flex-col h-full md:-mx-5">
								<div class="w-full lg:w-full md:px-5 mb-4">
									<label class="uppercase text-2xl font-bold block">
										e-mail
									</label>
									<div class="input">
										<input
											class="w-full bg-transparent"
											label="e-mail"
											name="email"
											id="email"
											v-model="form.email"
										/>
									</div>
								</div>
								<div
									class="message w-full md:px-5"
									:class="{ invisible: messageHidden }"
									v-html="message"
								></div>
								<div class="w-full md:px-5 mt-auto">
									<input
										class="submit text-2xl font-bold uppercase block"
										type="submit"
										value="Subscribe"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data: function () {
		return {
			form: {
				email: "",
			},
			consent: false,
			message: "",
			messageHidden: true,
		};
	},
	methods: {
		hideMessage: function () {
			setTimeout(() => {
				this.messageHidden = true;
			}, 3000);
		},
		subscribe: function (formData) {
			axios.post("chimp?do=subscribe", this.form).then((response) => {
				if (response.data.status === 400) {
					this.message = "Please enter valid email";
					this.messageHidden = false;
					this.hideMessage();
				} else {
					this.message = "Subscribed successfully";
					this.messageHidden = false;
					this.hideMessage();
				}
			});
		},
		update: function (formData) {
			axios.post("chimp?do=update", this.form).then((response) => {
				this.message = "Subscribed successfully";
				this.messageHidden = false;
				this.hideMessage();
			});
		},
		check: function () {
			if (this.form.email !== "") {
				if (this.consent === true) {
					axios.post("chimp?do=check", this.form).then((response) => {
						if (response.data.status === 404) {
							this.subscribe();
						}
						if (response.data.status === "subscribed") {
							this.messageHidden = false;
							this.message = "You are allready subscribed";
							this.hideMessage();
						}
						if (
							response.data.status === "unsubscribed" ||
							response.data.status === "archived"
						) {
							this.update();
						}
					});
				} else {
					this.message = "Please agree to the terms";
					this.messageHidden = false;
					this.hideMessage();
				}
			}
		},
	},
};
</script>
