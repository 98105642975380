<style lang="scss" scoped>
.buttons {
	> * {
		width: 20em;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	> * + * {
		margin-top: 70px;
	}
	@screen lg {
		flex-direction: row;
		justify-content: space-between;
		margin: 0 -10px;
		> * {
			padding: 0 10px;
			width: 24em;
		}
		> * + * {
			margin-top: 0;
		}
	}
	@screen xl {
		margin: 0 -20px;
		> * {
			width: 28em;
			padding: 0 20px;
		}
	}
}
/*Links*/
.link {
	position: relative;
}
.link::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-white;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
.link.link-tight::after {
	bottom: 0.3rem;
}
.find-us .link::after {
	bottom: 0.4rem;
}
.social .link::after {
	bottom: 0.4rem;
}
.link:hover::after {
	transform: scale(1);
	transform-origin: left;
}

.group:hover .animation-swipe {
	animation-play-state: running;
}
.animation-swipe {
	animation-name: swipe;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}
@keyframes swipe {
	0% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(-5px);
	}
}

/*Map*/
#map {
	@apply bg-grey;
	@apply w-full;
	@apply h-full;
	min-height: 70vh;

	@screen sm {
		min-height: 70vh;
	}
}
</style>

<template>
	<main class="mt-header text-white min-h-full-header overflow-hidden">
		<div class="mb-16 bg-white">
			<div class="buttons px-4 lg:inner-px w-full py-16 lg:py-24">
				<div v-for="button in buttons" class="inline-block">
					<c-button
						v-if="button.type === 'link'"
						:link="button.link"
						:text="button.text"
						type="external-link"
					></c-button>
					<c-button
						v-if="button.type === 'mailto'"
						:link="button.email"
						:text="button.text"
						type="mailto"
					></c-button>
				</div>
			</div>
		</div>
		<div class="flex flex-wrap -mx-5 px-5 mb-16 mt-20">
			<div class="w-full inner-pl pr-5 md:w-1/2">
				<div class="flex flex-col mb-16">
					<div class="flex flex-wrap mb-16">
						<div class="w-full mb-6 lg:w-2/3 lg:mb-0">
							<h2 class="uppercase font-bold text-xs mb-4">HQ OFFICES</h2>
							<div class="text-3xl leading-snug md:text-4xl">
								<text-reveal :text="hqOfficesAddress1"></text-reveal>
								<text-reveal :text="hqOfficesAddress2"></text-reveal>
								<text-reveal :text="hqOfficesAddress3"></text-reveal>

								<div class="flex text-3xl leading-snug md:text-4xl space-x-2">
									<text-reveal :text="`T. `"></text-reveal>
									<a
										class="link link-tight inline-block"
										:href="`tel:${hqOfficesPhone}`"
									>
										<text-reveal :text="`${hqOfficesPhone}`"></text-reveal>
									</a>
								</div>

								<div class="flex text-3xl leading-snug md:text-4xl space-x-2">
									<text-reveal :text="`E.`"></text-reveal>
									<a
										class="link link-tight inline-block"
										:href="`mailto:${hqOfficesEmail}`"
									>
										<text-reveal :text="`${hqOfficesEmail}`"></text-reveal>
									</a>
								</div>
							</div>
						</div>
						<div class="w-full lg:w-auto lg:ml-auto">
							<div class="flex items-center -mt-2 group">
								<a
									:href="hqMapLink"
									class="link uppercase font-bold text-xs mr-5"
									target="_blank"
									rel="noopener"
									>Open in Google Maps</a
								>
								<arrow-alt-right
									class="opacity-0 group-hover:opacity-100 transition-opacity duration-300 animation-swipe"
								></arrow-alt-right>
							</div>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full mb-6 lg:w-2/3 lg:mb-0">
							<h2 class="uppercase font-bold text-xs mb-4">FACTORY</h2>
							<div class="text-3xl leading-snug md:text-4xl">
								<text-reveal :text="factoryAddress1"></text-reveal>
								<text-reveal :text="factoryAddress2"></text-reveal>
								<text-reveal :text="factoryAddress3"></text-reveal>
								<text-reveal :text="factoryAddress4"> </text-reveal>

								<div class="flex text-3xl leading-snug md:text-4xl space-x-2">
									<text-reveal :text="`T. `"></text-reveal>
									<a
										class="link link-tight inline-block"
										:href="`tel:${factoryPhone}`"
									>
										<text-reveal :text="`${factoryPhone}`"></text-reveal>
									</a>
								</div>

								<div class="flex text-3xl leading-snug md:text-4xl space-x-2">
									<text-reveal :text="`E.`"></text-reveal>
									<a
										class="link link-tight inline-block"
										:href="`mailto:${factoryEmail}`"
									>
										<text-reveal :text="`${factoryEmail}`"></text-reveal>
									</a>
								</div>
							</div>
						</div>
						<div class="w-full lg:w-auto lg:ml-auto">
							<div class="flex items-center -mt-2 group">
								<a
									:href="factoryMapLink"
									class="link uppercase font-bold text-xs mr-5"
									target="_blank"
									rel="noopener"
									>Open in Google Maps</a
								>
								<arrow-alt-right
									class="opacity-0 group-hover:opacity-100 transition-opacity duration-300 animation-swipe"
								></arrow-alt-right>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="w-full px-5 md:pl-5 md:pr-0 md:w-1/2">
				<div id="map">
					<GmapMap
						:center="{
							lat: parseFloat(mapsCenterLat),
							lng: parseFloat(mapsCenterLng),
						}"
						:zoom="parseFloat(mapsZoom)"
						style="width: 100%; height: 100%"
						:options="{
							mapTypeControl: false,
							streetViewControl: false,
							styles: mapStyles,
						}"
					>
						<GmapMarker
							:key="index"
							v-for="(marker, index) in mapsMarkers"
							:position="{
								lat: parseFloat(marker.lat),
								lng: parseFloat(marker.lng),
							}"
							:clickable="true"
							:draggable="false"
							:icon="{ url: require('../../../../assets/images/marker.png') }"
							@click="center = marker.position"
						/>
					</GmapMap>
				</div>
			</div>
		</div>

		<div class="flex flex-wrap -mx-5 px-5 mb-16 md:mb-20">
			<div class="find-us w-full inner-px mb-16 md:w-2/3 md:mb-0">
				<h2 class="uppercase font-bold text-xs mb-4">FIND US:</h2>
				<p class="text-4xl leading-tight md:text-5xl" style="max-width: 12em">
					<text-reveal :text="contactText"> </text-reveal>
				</p>
				<image-reveal
					class="md:w-1/2 sm:max-w-xs mt-16 md:mt-16"
					imageSrc="/assets/images/banner-psifiakos.png"
					bgColor="bg-black"
				></image-reveal>
			</div>
			<div class="w-full px-5 md:w-1/3 md:ml-auto">
				<h2 class="uppercase font-bold text-xs mb-4">Follow us:</h2>
				<ul class="social text-3xl leading-tight md:text-5xl">
					<li>
						<a
							:href="facebook"
							class="link inline-block"
							target="_blank"
							rel="noopener"
							><text-reveal text="Facebook"></text-reveal
						></a>
					</li>
					<li>
						<a
							:href="instagram"
							class="link inline-block"
							target="_blank"
							rel="noopener"
							><text-reveal text="Instagram"></text-reveal
						></a>
					</li>
					<li>
						<a
							:href="youtube"
							class="link inline-block"
							target="_blank"
							rel="noopener"
							><text-reveal text="Youtube"></text-reveal
						></a>
					</li>
					<li>
						<a
							:href="linkedin"
							class="link inline-block"
							target="_blank"
							rel="noopener"
							><text-reveal text="LinkedIn"></text-reveal
						></a>
					</li>
				</ul>
			</div>
		</div>
	</main>
</template>

<script>
import arrowAltRight from "../svg/arrow-alt-right.vue";
import cButton from "../c-button.vue";

export default {
	components: {
		arrowAltRight,
		cButton,
	},
	data: function () {
		return {
			buttons: "",
			hqOfficesAddress1: "",
			hqOfficesAddress2: "",
			hqOfficesAddress3: "",
			hqOfficesPhone: "",
			hqOfficesEmail: "",
			hqMapLink: "",
			factoryAddress1: "",
			factoryAddress2: "",
			factoryAddress3: "",
			factoryAddress4: "",
			factoryPhone: "",
			factoryEmail: "",
			factoryMapLink: "",
			mapsCenterLat: 0,
			mapsCenterLng: 0,
			mapsZoom: 10,
			mapsMarkers: [],
			contactText: "",
			phone: "",
			email: "",
			facebook: "",
			instagram: "",
			youtube: "",
			linkedin: "",
			mapStyles: [
				{
					featureType: "administrative.province",
					elementType: "all",
					stylers: [
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "administrative.locality",
					elementType: "labels",
					stylers: [
						{
							lightness: "-8",
						},
					],
				},
				{
					featureType: "administrative.locality",
					elementType: "labels.text.fill",
					stylers: [
						{
							color: "#000000",
						},
					],
				},
				{
					featureType: "administrative.locality",
					elementType: "labels.text.stroke",
					stylers: [
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "administrative.neighborhood",
					elementType: "all",
					stylers: [
						{
							color: "#acacac",
						},
					],
				},
				{
					featureType: "administrative.neighborhood",
					elementType: "labels.text.fill",
					stylers: [
						{
							color: "#484848",
						},
					],
				},
				{
					featureType: "administrative.neighborhood",
					elementType: "labels.text.stroke",
					stylers: [
						{
							color: "#ff0000",
						},
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "administrative.land_parcel",
					elementType: "all",
					stylers: [
						{
							lightness: "-3",
						},
					],
				},
				{
					featureType: "landscape",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: "72",
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "landscape",
					elementType: "labels",
					stylers: [
						{
							lightness: "23",
						},
					],
				},
				{
					featureType: "poi",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: "30",
						},
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "road",
					elementType: "all",
					stylers: [
						{
							lightness: "-19",
						},
					],
				},
				{
					featureType: "road",
					elementType: "geometry",
					stylers: [
						{
							lightness: "2",
						},
						{
							gamma: "1.21",
						},
					],
				},
				{
					featureType: "road",
					elementType: "geometry.stroke",
					stylers: [
						{
							visibility: "off",
						},
						{
							saturation: "15",
						},
						{
							hue: "#ff0000",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels",
					stylers: [
						{
							lightness: "-43",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.text",
					stylers: [
						{
							visibility: "on",
						},
						{
							lightness: "22",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.text.fill",
					stylers: [
						{
							weight: "0.12",
						},
						{
							lightness: "-23",
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.text.stroke",
					stylers: [
						{
							visibility: "off",
						},
						{
							lightness: "71",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.icon",
					stylers: [
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "road.highway",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							visibility: "simplified",
						},
					],
				},
				{
					featureType: "road.arterial",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: 30,
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "road.local",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: 40,
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "transit",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							visibility: "simplified",
						},
					],
				},
				{
					featureType: "transit",
					elementType: "geometry.fill",
					stylers: [
						{
							saturation: "5",
						},
						{
							visibility: "on",
						},
						{
							lightness: "5",
						},
					],
				},
				{
					featureType: "water",
					elementType: "geometry",
					stylers: [
						{
							hue: "#ffff00",
						},
						{
							lightness: "-24",
						},
						{
							saturation: -97,
						},
					],
				},
				{
					featureType: "water",
					elementType: "geometry.fill",
					stylers: [
						{
							saturation: "-88",
						},
						{
							lightness: "-23",
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "water",
					elementType: "labels",
					stylers: [
						{
							visibility: "on",
						},
						{
							lightness: -25,
						},
						{
							saturation: -100,
						},
					],
				},
				{
					featureType: "water",
					elementType: "labels.text",
					stylers: [
						{
							weight: "0.01",
						},
						{
							lightness: "9",
						},
					],
				},
				{
					featureType: "water",
					elementType: "labels.text.fill",
					stylers: [
						{
							lightness: "-32",
						},
						{
							gamma: "2.99",
						},
					],
				},
			],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData: function () {
			this.endpoint = this.$router.currentRoute.path + ".json";
			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}
			axios.get(this.endpoint).then((response) => {
				this.buttons = response.data.buttons;
				this.button1 = response.data.button1;
				this.button2 = response.data.button2;
				this.button2Subject = response.data.button2Subject;
				this.button3 = response.data.button3;
				this.hqOfficesAddress1 = response.data.hqOfficesAddress1;
				this.hqOfficesAddress2 = response.data.hqOfficesAddress2;
				this.hqOfficesAddress3 = response.data.hqOfficesAddress3;
				this.hqOfficesPhone = response.data.hqOfficesPhone;
				this.hqOfficesEmail = response.data.hqOfficesEmail;
				this.hqMapLink = response.data.hqMapLink;
				this.factoryAddress1 = response.data.factoryAddress1;
				this.factoryAddress2 = response.data.factoryAddress2;
				this.factoryAddress3 = response.data.factoryAddress3;
				this.factoryAddress4 = response.data.factoryAddress4;
				this.factoryPhone = response.data.factoryPhone;
				this.factoryEmail = response.data.factoryEmail;
				this.factoryMapLink = response.data.factoryMapLink;
				this.mapsCenterLat = response.data.mapsCenterLat;
				this.mapsCenterLng = response.data.mapsCenterLng;
				this.mapsZoom = response.data.mapsZoom;
				this.mapsMarkers = response.data.mapsMarkers;
				this.contactText = response.data.contactText;
				this.phone = response.data.phone;
				this.email = response.data.email;
				this.facebook = response.data.facebook;
				this.instagram = response.data.instagram;
				this.youtube = response.data.youtube;
				this.linkedin = response.data.linkedin;
			});
		},
	},
};
</script>
