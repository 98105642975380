<style lang="scss" scoped>
.marquee {
	overflow-x: hidden;
	cursor: pointer;
	transition: background-color 0.08s, color 0.08s;
	transition-delay: 0.08s;
}
.marquee__inner {
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	min-width: 100%;
}
.marquee__message {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	animation: slide-right 20s linear infinite;
	animation-play-state: running;
}

.marquee__message span {
	padding: 0 1rem;
}

.marquee:hover .marquee__message {
	animation-play-state: paused;
}

@keyframes slide-right {
	from {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
	to {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
</style>

<template>
	<div
		class="marquee uppercase font-bold py-2 text-2xl"
		@click="$root.nlFormOpen = !$root.nlFormOpen"
		:class="$root.nlFormOpen ? 'bg-white text-black' : 'bg-black text-white'"
	>
		<div class="marquee__inner">
			<div class="marquee__message">
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
			</div>
			<div class="marquee__message">
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
			</div>
			<div class="marquee__message">
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
				<span>Subscribe to our newsletter</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>
