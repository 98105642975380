<style lang="css" scoped>
li a {
	position: relative;
}
li a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.45s;
	transform-origin: right;
}
li a:hover::after {
	transform: scale(1);
	transform-origin: left;
}

li a.router-link-exact-active::after {
	transform: scale(1);
}

ul li ul li ul {
	padding-left: 10px;
}
</style>

<template>
	<transition name="fade-in-slide-left">
		<div
			id="products-menu-mobile"
			v-if="$root.navMbOpen && $root.prodMenuMbOpen"
			class="fixed bg-grey w-full h-full-header top-header left-0 z-50 md:hidden"
		>
			<ul class="py-8 flex flex-col overflow-scroll h-full space-y-4">
				<li class="inner-pl-mobile pr-10">
					<router-link
						@click.native="$parent.navMbOpen = false"
						to="/products"
						class="uppercase font-bold text-xs"
					>
						{{ $t("links.allProducts") }}
					</router-link>
				</li>
				<li
					v-for="category in categories"
					class="inner-pl-mobile pr-10"
					:key="category.index"
				>
					<router-link
						@click.native="$parent.navMbOpen = false"
						:to="'/' + category.uri"
						class="uppercase font-bold text-xs inline-block mb-4"
					>
						{{ category.title }}
					</router-link>
					<template v-if="category.subcategories">
						<ul>
							<li
								v-for="subcategory in category.subcategories"
								class="mb-2"
								:key="subcategory.index"
							>
								<router-link
									@click.native="$parent.navMbOpen = false"
									:to="'/' + subcategory.uri"
									:class="[
										subcategory.subSubcategories.length
											? 'mb-2 inline-block'
											: 'mb-0',
										'uppercase text-xs',
									]"
								>
									{{ subcategory.title }}
								</router-link>
								<!-- Subcategory has subcategories -->
								<ul v-if="subcategory.subSubcategories.length">
									<li
										v-for="subSubcategory in subcategory.subSubcategories"
										:key="subSubcategory.index"
										class="mb-2"
									>
										<router-link
											@click.native="$parent.navMbOpen = false"
											:to="'/' + subSubcategory.transformedUri"
											class="uppercase text-xs"
										>
											{{ subSubcategory.title }}
										</router-link>
									</li>
								</ul>
							</li>
						</ul>
					</template>
				</li>
			</ul>
		</div>
	</transition>
</template>

<script>
export default {
	data: function () {
		return {
			showByIndex: null,
			categories: null,
			endpoint: "/products-menu.json",
		};
	},
	mounted() {
		if (this.$root.language == "el") {
			this.endpoint = "/el/products-menu.json";
		}
		axios
			.get(this.endpoint)
			.then((response) => (this.categories = response.data.categories));
	},
};
</script>
