<style lang="css" scoped>
a {
	position: relative;
}
a::after {
	content: "";
	width: 100%;
	transform: scale(0);
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -0.3rem;
	@apply bg-black;
	transition: transform 0.25s, background-color 1s;
	transform-origin: right;
}
a:hover::after {
	transform: scale(1);
	transform-origin: left;
}
</style>

<template>
	<main class="mt-header min-h-full-header">
		<div class="h-full-header flex flex-wrap">
			<div
				v-for="(page, key, index) in pages"
				:key="page.title"
				class="w-full flex items-center justify-center md:w-1/2 transition-text-bg-colors duration-200"
				:class="{ 'border-b md:border-0 md:border-r': index === 0 }"
				:style="active == page.title ? { 'background-color': randomColor } : ''"
				@mouseenter="active = page.title"
				@mouseleave="active = ''"
			>
				<router-link class="text-3xl leading-none md:text-6xl" :to="page.uri">
					{{ page.title }}
				</router-link>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			pages: "",
			colors: "",
			randomColor: "",
			cycle: "",
			active: "",
		};
	},
	mounted() {
		this.getData();
	},
	watch: {
		active: function () {
			this.randomize();
			if (this.cycle) {
				clearInterval(this.cycle);
			}
			if (this.active != "") {
				this.cycle = setInterval(() => {
					this.randomize();
				}, 400);
			}
		},
	},
	methods: {
		randomize: function () {
			console.log("randomizing");
			const idx = Math.floor(Math.random() * this.colors.length);
			this.randomColor = this.colors[idx];
		},
		getData: function () {
			this.endpoint = this.$router.currentRoute.path + ".json";
			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}
			axios
				.get(this.endpoint)
				.then(
					(response) => (
						(this.pages = response.data.pages),
						(this.colors = response.data.colors)
					)
				);
		},
	},
};
</script>
