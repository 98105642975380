<style lang="scss" scoped>
.button {
	padding: 10px 60px;
	height: 110px;
	@screen lg {
		height: 140px;
	}
	display: flex;
	align-items: center;
	&__bg {
		width: 120px;
		height: 100%;
		border-radius: 99999px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		transition: width 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97);
		background-color: black;
		.button.is-active & {
			width: 100%;
		}
		.button--negative & {
			background-color: white;
		}
	}
	> div {
		@apply text-xl;
		@apply z-10;
		@apply text-center;
		@apply leading-tight;
		@apply font-bold;
		.button--negative & {
			@apply text-black;
			color: red;
		}
		@screen lg {
			@apply text-2xl;
		}
		@screen xl {
			@apply text-3xl;
		}
	}
}
</style>

<template>
	<div>
		<a
			v-if="type === 'mailto'"
			:href="`mailto:${link}?subject=${subject}`"
			:class="[
				isRevealed ? 'is-active' : '',
				negative ? 'button--negative' : '',
				'button flex justify-center relative',
			]"
			v-observe-visibility="{
				callback: visibilityChanged,
				throttle: 50,
				once: true,
			}"
		>
			<div class="button__bg"></div>
			<div>
				<text-reveal :text="text"></text-reveal>
			</div>
		</a>
		<a
			v-if="type === 'external-link'"
			:href="link"
			target="_blank"
			rel="noopener"
			:class="[
				isRevealed ? 'is-active' : '',
				negative ? 'button--negative' : '',
				'button flex justify-center relative',
			]"
			v-observe-visibility="{
				callback: visibilityChanged,
				throttle: 50,
				once: true,
			}"
		>
			<div class="button__bg"></div>
			<div>
				<text-reveal :text="text"></text-reveal>
			</div>
		</a>
		<router-link
			v-if="type === 'router-link'"
			:to="link"
			:class="[
				isRevealed ? 'is-active' : '',
				negative ? 'button--negative' : '',
				'button flex justify-center relative',
			]"
			v-observe-visibility="{
				callback: visibilityChanged,
				throttle: 50,
				once: true,
			}"
		>
			<div class="button__bg"></div>
			<div>
				<text-reveal :text="text"></text-reveal>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
			default: "Text",
		},
		link: {
			type: String,
		},
		subject: {
			type: String,
			required: false,
		},
		negative: {
			type: Boolean,
		},
		type: {
			type: String,
			required: true,
			default: "router-link",
		},
	},
	data: function () {
		return {
			isRevealed: false,
		};
	},
	methods: {
		visibilityChanged(isVisible, entry) {
			this.isRevealed = isVisible;
		},
	},
};
</script>
