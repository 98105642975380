<style lang="scss" scoped>
.marquee {
	overflow-x: hidden;
	cursor: pointer;
	transition: background-color 0.08s, color 0.08s;
	transition-delay: 0.08s;
}
.marquee__inner {
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	min-width: 100%;
}
.marquee__message {
	display: flex;
	flex-shrink: 0;
	width: 200%;
	@screen md {
		width: 150%;
	}
	@screen lg {
		width: 100%;
	}
	align-items: center;
	animation: slide-left 20s linear infinite;
	animation-play-state: running;
}
.marquee__message:nth-child(2) {
	margin-left: -1px;
}
.marquee:hover .marquee__message {
	animation-play-state: paused;
}
@keyframes slide-left {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
</style>

<template>
	<div class="marquee">
		<div class="marquee__inner">
			<div class="marquee__message" v-for="i in 2">
				<template v-for="feedPost in feedPosts">
					<box :box="feedPost"></box>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import box from "./box.vue";

export default {
	components: {
		box,
	},
	data: function () {
		return {
			sliceStart: null,
			sliceEnd: null,
			index: 1,
			feedPosts: [],
		};
	},
	mounted() {
		this.endpoint = this.$router.currentRoute.path + "home.json";
		// If language is el prepend language segment to the endpoint
		if (this.$root.language == "el") {
			this.endpoint = "/el" + this.endpoint;
		}
		axios
			.get(this.endpoint)
			.then((response) => (this.feedPosts = response.data.feedPosts));
	},
	methods: {
		updateSliceStart: function (sliceStart, index) {
			switch (index) {
				case 0:
					return 0;
					break;
				case 1:
					return 4;
					break;
				case 2:
					return 8;
			}
		},
		updateSliceEnd: function (sliceEnd, index) {
			switch (index) {
				case 0:
					return 4;
					break;
				case 1:
					return 8;
					break;
				case 2:
					return 12;
			}
		},
	},
};
</script>
