import Vue from "vue";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import VueObserveVisibility from "vue-observe-visibility";
import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from "vue-browser-geolocation";

window.axios = require("axios");

import "simplebar";
import "simplebar/dist/simplebar.css";

import OnLoad from "vue-onload";

import VShowSlide from "v-show-slide";

// Pages
import home from "./components/pages/home.vue";
Vue.component("home", home);

import about from "./components/pages/about.vue";
Vue.component("about", about);

import howToSpray from "./components/pages/howToSpray.vue";
Vue.component("how-to-spray", howToSpray);

import privateLabel from "./components/pages/privateLabel.vue";
Vue.component("private-label", privateLabel);

import products from "./components/pages/products.vue";
Vue.component("products", products);

import product from "./components/pages/product.vue";
Vue.component("product", product);

import sponsorshipsAndCollaborations from "./components/pages/sponsorshipsAndCollaborations.vue";
Vue.component("sponsorships-and-collaborations", sponsorshipsAndCollaborations);

import instructions from "./components/pages/instructions.vue";
Vue.component("instructions", instructions);

import article from "./components/pages/article.vue";
Vue.component("i-article", article);

import contact from "./components/pages/contact.vue";
Vue.component("contact", contact);

import findAStore from "./components/pages/findAStore.vue";
Vue.component("find-a-store", findAStore);

// Subcomponents
import pageNavigationMobile from "./components/pageNavigationMobile.vue";
Vue.component("page-navigation-mobile", pageNavigationMobile);

import pageNavigationDesktop from "./components/pageNavigationDesktop.vue";
Vue.component("page-navigation-desktop", pageNavigationDesktop);

import productsFilters from "./components/productsFilters.vue";
Vue.component("products-filters", productsFilters);

import productsMenuMobile from "./components/productsMenuMobile.vue";
Vue.component("products-menu-mobile", productsMenuMobile);

import productsMenuDesktop from "./components/productsMenuDesktop.vue";
Vue.component("products-menu-desktop", productsMenuDesktop);

import newsletterRibbon from "./components/newsletterRibbon.vue";
Vue.component("newsletter-ribbon", newsletterRibbon);

import newsletterForm from "./components/newsletterForm.vue";
Vue.component("newsletter-form", newsletterForm);

import cInput from "./components/cInput.vue";
Vue.component("c-input", cInput);

import cCheckbox from "./components/cCheckbox.vue";
Vue.component("c-checkbox", cCheckbox);

import cCheckboxFilter from "./components/cCheckboxFilter.vue";
Vue.component("c-checkbox-filter", cCheckboxFilter);

import textButton from "./components/textButton.vue";
Vue.component("text-button", textButton);

import cLink from "./components/cLink.vue";
Vue.component("c-link", cLink);

import cRouterLink from "./components/cRouterLink.vue";
Vue.component("c-router-link", cRouterLink);

import textReveal from "./components/textReveal.vue";
Vue.component("text-reveal", textReveal);

import imageReveal from "./components/imageReveal.vue";
Vue.component("image-reveal", imageReveal);

import videoReveal from "./components/videoReveal.vue";
Vue.component("video-reveal", videoReveal);

import iframeReveal from "./components/iframeReveal.vue";
Vue.component("iframe-reveal", iframeReveal);

// Svg
import logo from "./components/svg/logo.vue";
Vue.component("logo", logo);

import clLogo from "./components/svg/cl-logo.vue";
Vue.component("cl-logo", clLogo);

import iconFb from "./components/svg/iconFb.vue";
Vue.component("icon-fb", iconFb);

import iconIg from "./components/svg/iconIg.vue";
Vue.component("icon-ig", iconIg);

import iconYt from "./components/svg/iconYt.vue";
Vue.component("icon-yt", iconYt);

import iconLi from "./components/svg/iconLi.vue";
Vue.component("icon-li", iconLi);

import iconTt from "./components/svg/iconTt.vue";
Vue.component("icon-tt", iconTt);

import iconPt from "./components/svg/iconPt.vue";
Vue.component("icon-pt", iconPt);

import iconMl from "./components/svg/iconMl.vue";
Vue.component("icon-ml", iconMl);

import iconPhone from "./components/svg/iconPhone.vue";
Vue.component("icon-phone", iconPhone);

import iconLocation from "./components/svg/iconLocation.vue";
Vue.component("icon-location", iconLocation);

import iconCollapse from "./components/svg/iconCollapse.vue";
Vue.component("icon-collapse", iconCollapse);

import hand from "./components/svg/hand.vue";
Vue.component("hand", hand);

import closeMini from "./components/svg/close-mini.vue";
Vue.component("close-mini", closeMini);

import newEn from "./components/svg/sticker-new-en.vue";
Vue.component("new-en", newEn);

import newEl from "./components/svg/sticker-new-el.vue";
Vue.component("new-el", newEl);

import oops from "./components/svg/oops.vue";
Vue.component("oops", oops);

// Prevent scrolling on history change
window.history.scrollRestoration = "manual";

const routes = [
	// Homepage
	{
		path: "/",
		component: home,
		props: true,
		name: "homepage",
	},
	// About
	{
		path: "/about",
		component: about,
		props: true,
		name: "about",
	},
	// Products
	{
		path: "/products",
		component: products,
		props: true,
		name: "products",
	},
	// Products category
	{
		path: "/products/:category",
		component: products,
		props: true,
		name: "products",
	},
	// Products subcategory
	{
		path: "/products/:category/:subcategory",
		component: products,
		props: true,
		name: "products",
	},
	// Products subSubcategory
	{
		path: "/products/:category/:subcategory/subcategory/:subSubcategory",
		component: products,
		meta: { isSubSub: true },
		props: true,
		name: "products",
	},
	// Product
	{
		path: "/products/:category/:subcategory/:productId",
		component: product,
		props: true,
		name: "product",
	},
	// Sub product
	{
		path: "/products/:category/:subcategory/:subSubcategory/:productId",
		component: product,
		props: true,
		name: "subProduct",
	},
	// Sponsorships & collaborations
	{
		path: "/sponsorships-and-collaborations",
		component: sponsorshipsAndCollaborations,
		props: true,
		name: "sponsorshipsAndCollaborations",
	},
	// Article
	{
		path: "/sponsorships-and-collaborations/:articleId",
		component: article,
		props: true,
		name: "article",
	},
	// Private Label
	{
		path: "/private-label",
		component: privateLabel,
		props: true,
		name: "privateLabel",
	},
	// Contact
	{
		path: "/contact",
		component: contact,
		props: true,
		name: "contact",
	},
	// Find A Store
	{
		path: "/find-a-store",
		component: findAStore,
		props: true,
		name: "findAStore",
	},
];

var base = null;

if (document.documentElement.lang == "el") {
	base = "el";
}

const router = new VueRouter({
	mode: "history",
	base: base,
	routes,
});

const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: "auto" });
};

router.beforeEach(function (to, from, next) {
	// Load translations from Kirby
	loadLanguageAsync(document.documentElement.lang).then(() => next());

	if (
		// On route change wait for transition to end and then scroll to top
		(from.name === "products" && to.name === "product") ||
		(from.name === "subcategory" && to.name === "product")
	) {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 700);
		productsScrollPosition = window.pageYOffset;
		next();
	} else if (
		(from.name === "product" && to.name === "products") ||
		(from.name === "product" && to.name === "subcategory")
	) {
		document.documentElement.style.setProperty(
			"--productsScrollPosition",
			`${productsScrollPosition}px`
		);
		setTimeout(() => {
			window.scrollTo(0, productsScrollPosition);
		}, 700);
		next();
	} else if (from.name === "product" && to.name === "product") {
		scrollToTop();
		next();
	} else {
		setTimeout(() => {
			scrollToTop();
		}, 300);
		next();
	}
});

router.afterEach(function (to, from) {
	if (
		(from.name === "product" && to.name === "products") ||
		(from.name === "product" && to.name === "subcategory")
	) {
		setTimeout(() => {
			// window.scrollBy(0, productsScrollPosition);
		}, 500);
	}
});

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(OnLoad);
Vue.use(VShowSlide);
Vue.use(VueObserveVisibility);

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyDX1sX8qMcmGd2umopzAi5tSTynzKKtNSI',
	},
})

Vue.use(VueGeolocation);

export const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
});

export function loadLanguageAsync(lang) {
	let endpoint = "/translations.json";
	// If language is el prepend language segment to the endpoint
	if (lang == "el") {
		endpoint = "/el" + endpoint;
	}
	return axios.get(endpoint).then((response) => {
		let messages = response.data;
		i18n.setLocaleMessage(lang, messages);
		i18n.locale = lang;
	});
}

var productsScrollPosition = 0;

new Vue({
	el: "#app",
	router,
	i18n,
	data: {
		show: false,
		language: document.documentElement.lang,
		navMbOpen: false,
		prodMenuDtOpen: false,
		prodMenuMbOpen: false,
		nlFormOpen: false,
		prodsFilterOpen: false,
		transitionName: null,
		transitionMode: null,
	},
	computed: {
		bgColor: function () {
			if (
				this.$route.matched.some(({ name }) =>
					/home|about|contact|privateLabel|findAStore$/.test(name)
				)
			) {
				return "bg-black";
			} else if (
				this.$route.matched.some(({ name }) => /how to spray/.test(name))
			) {
				return "bg-grey";
			} else {
				return "bg-white";
			}
		},
		isNegative: function () {
			return this.$route.matched.some(({ name }) =>
				/home|about|contact|privateLabel|findAStore$/.test(name)
			);
		},
	},
	watch: {
		$route(to, from) {
			//Close desktop products menu when navigating to new page
			this.prodMenuDtOpen = false;
			//Close mobile page navigation when navigating to new page
			this.navMbOpen = false;

			if (
				(from.name === "product" && to.name === "subcategory") ||
				(from.name === "product" && to.name === "products")
			) {
				//Routing from product to products
				this.transitionName = "slide-right-parallel";
				this.transitionMode = "";
			} else if (
				(from.name === "subcategory" && to.name === "product") ||
				(from.name === "products" && to.name === "product")
			) {
				//Routing from products to product
				this.transitionName = "slide-left-parallel";
				this.transitionMode = "";
			} else {
				this.transitionName = "";
				this.transitionMode = "";
			}
		},
		prodsFilterOpen() {
			// If we open filters menu from a non products page redirect to products
			if (this.$route.name !== "products" && this.prodsFilterOpen) {
				router.push({
					name: "products",
					// And pass props
					params: {
						flLeft: false,
						fldLeft: false,
					},
				});
			}
		},
	},
	methods: {
		toggleNavMb: function (event) {
			this.navMbOpen = !this.navMbOpen;
			if (this.navMbOpen) {
				this.prodMenuMbOpen = false;
			}
		},
	},
	mounted() {
		this.$nextTick(function () {
			this.show = true;
		});
		document.addEventListener("scroll", function (e) {
			var marquee = document.querySelector(".marquee");
			var filtersToggle = document.getElementById("filters-toggle");
			var marqueeDistance = marquee.getBoundingClientRect().top;
			const vh = Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0
			);
			if (filtersToggle) {
				if (marqueeDistance < vh / 2 + 200) {
					filtersToggle.classList.add("opacity-0");
				} else {
					filtersToggle.classList.remove("opacity-0");
				}
			}
		});
	},
});

// Open exteranl links in new tab
// https://stackoverflow.com/questions/12987963/open-external-links-in-a-new-tab-without-jquery
function externalLinks() {
	for (var c = document.getElementsByTagName("a"), a = 0; a < c.length; a++) {
		var b = c[a];
		b.getAttribute("href") &&
			b.hostname !== location.hostname &&
			(b.target = "_blank");
	}
}
externalLinks();
