<style lang="scss" scoped>
/*Color pills*/
.color-pill {
	background-position: center;
	background-size: cover;
}
.color-pill.is-active {
	position: relative;
}
.color-pill.is-active::before,
.color-pill.is-active::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	border-radius: 6px;
}
.color-pill.is-active::after {
	top: -2px;
	left: -2px;
	border: 2px solid white;
	border-radius: 6px;
}
.color-pill.is-active::before {
	border: 1px solid black;
	top: -3px;
	left: -3px;
	padding: 2px;
}

.share-label {
	position: relative;
}
.share-label::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 1px;
	@apply bg-black;
	transition: width 0.85s;
}
.share-label.revealed::before {
	width: 75%;
}
.cap-image {
	box-sizing: content-box;
	padding-right: 6px;
	width: 45px;
	min-width: 45px;
	position: relative;
}
.cap-image::after {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
	height: 1px;
	@apply bg-black;
	transition: height 0.85s;
}
.cap-image.revealed::after {
	height: 100%;
}
</style>

<style>
.cosmos-prose {
	@apply space-y-2;
}
.cosmos-prose ul li {
	padding-left: 1em;
	text-indent: -1em;
}
.cosmos-prose ul li:before {
	content: "-";
	padding-right: 5px;
}
</style>

<template>
	<main class="mt-header min-h-full-header">
		<!-- Row -->
		<div class="flex flex-wrap mb-16" :key="this.productId">
			<productGallery :productGallery="product.gallery"></productGallery>

			<!-- Col 2 -->
			<div class="pt-12 pl-5 pb-12 pr-5 md:pr-0 w-full md:w-4/12 bg-white">
				<transition name="fade" mode="out-in">
					<div v-if="this.loaded === true">
						<div class="uppercase text-2xl leading-tight mb-6">
							<div class="product-line">{{ product.line }} LINE</div>
							<div>–</div>
							<div class="text-lg">
								<text-reveal :text="product.title"></text-reveal>
								<text-reveal :text="product.subtitle"></text-reveal>
							</div>
						</div>

						<!-- Description -->
						<template v-if="product.description">
							<h2 class="uppercase text-xs font-bold mb-2">Description</h2>

							<div
								class="mb-8 text-xs cosmos-prose"
								v-html="product.description"
							></div>
						</template>

						<!-- Features -->
						<template v-if="product.features">
							<h2 class="uppercase text-xs font-bold mb-2">Features</h2>

							<div
								class="cosmos-prose text-xs mb-8"
								v-html="product.features"
							></div>
						</template>

						<!-- Application -->
						<template v-if="product.application">
							<h2 class="uppercase text-xs font-bold mb-2">Application</h2>

							<div
								class="cosmos-prose text-xs mb-8"
								v-html="product.application"
							></div>
						</template>

						<!-- Cap System -->
						<template v-if="product.caps">
							<h2 class="uppercase text-xs font-bold mb-2">Cap System</h2>

							<ul class="flex flex-wrap mb-8">
								<li
									class="flex w-full sm:w-1/2 md:w-full lg:w-1/2 mb-3"
									v-for="cap in product.caps"
								>
									<div
										v-observe-visibility="{
											callback: visibilityChanged,
											throttle: 300,
											once: true,
										}"
										class="cap-image"
									>
										<img class="w-full" :src="cap.image" alt="" />
									</div>
									<div class="px-2">
										<div class="uppercase text-xs">{{ cap.title }}</div>
										<div class="text-xs" v-html="cap.description"></div>
									</div>
								</li>
							</ul>
						</template>

						<!-- Color Range -->
						<h2 class="text-xs uppercase font-bold">Color Range</h2>

						<!-- Colors -->
						<ul class="flex flex-wrap mb-8">
							<li
								class="group w-1/2 sm:w-1/3 lg:w-1/4"
								v-for="color in product.colorRange"
							>
								<router-link :to="color.uri">
									<ul
										class="text-xxs uppercase mt-4 mb-1 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
									>
										<li
											class="whitespace-no-wrap overflow-ellipsis overflow-hidden"
										>
											{{ color.code }}
										</li>
										<li class="truncate">
											{{ color.name }}
										</li>
									</ul>
									<div
										class="color-pill rounded h-2 w-20"
										:style="[
											color.imageColor != 'true'
												? { 'background-color': color.value }
												: { backgroundImage: 'url(' + color.value + ')' },
										]"
										:class="{ 'is-active': color.self }"
									></div>
								</router-link>
							</li>
						</ul>

						<!-- Instructions for use -->
						<template v-if="product.instructions">
							<h2 class="uppercase text-xs font-bold mb-2">
								Instructions for use
							</h2>

							<div
								class="text-xs mb-8 cosmos-prose"
								v-html="product.instructions"
							></div>
						</template>
						<c-link
							v-if="product.pdf"
							class="block uppercase text-xs font-bold mb-20"
							text="Download product info as PDF"
							:url="product.pdf"
							newTab="true"
						></c-link>

						<div class="flex items-center">
							<div
								v-observe-visibility="{
									callback: visibilityChanged,
									throttle: 300,
									once: true,
								}"
								class="share-label uppercase text-xs mr-6 pt-4 pb-3"
							>
								Share this product:
							</div>
							<ul class="space-x-6 flex">
								<li class="wiggle">
									<a :href="product.fbShareUrl" target="_blank" rel="noopener"
										><icon-fb></icon-fb
									></a>
								</li>
								<li class="wiggle">
									<a :href="product.ttShareUrl" target="_blank" rel="noopener"
										><icon-tt></icon-tt
									></a>
								</li>
								<li class="wiggle">
									<a :href="product.liShareUrl" target="_blank" rel="noopener"
										><icon-li></icon-li
									></a>
								</li>
								<li class="wiggle">
									<icon-pt></icon-pt>
								</li>
								<li class="wiggle">
									<a :href="product.mlShareUrl" target="_blank" rel="noopener"
										><icon-ml></icon-ml
									></a>
								</li>
							</ul>
						</div>
					</div>
				</transition>
			</div>
			<div
				class="hidden w-full bg-white md:block md:w-2/12 md:sticky md:pl-0 top-header h-6 text-right pl-5 pr-6 pt-12"
			>
				<c-router-link
					class="block uppercase text-xs font-bold"
					:text="$t('links.backToProducts')"
					:url="parentLink"
				></c-router-link>
			</div>
		</div>

		<similar-products
			:similarProducts="product.similarProducts"
		></similar-products>
	</main>
</template>

<script>
import productGallery from "../productGallery.vue";
import similarProducts from "../similarProducts.vue";

export default {
	components: {
		productGallery,
		similarProducts,
	},
	props: {
		productId: {},
		subcategory: {},
		subSubcategory: {},
		category: {},
	},
	data: function () {
		return {
			product: "",
			endpoint: null,
			loaded: false,
		};
	},
	// beforeRouteEnter (to, from, next) {
	// 	axios
	// 	.get('/products/' + to.params.category + '/' + to.params.subcategory  + '/' + to.params.productId  + '.json')
	// 	.then(response => (
	// 		next(vm => vm.getProduct())
	// 		))
	// },
	mounted() {
		this.getProduct();
	},
	watch: {
		$route(to, from) {
			this.getProduct();
		},
	},
	computed: {
		parentLink: function () {
			if (this.subSubcategory) {
				return `/products/${this.category}/${this.subcategory}/subcategory/${this.subSubcategory}`;
			} else {
				return `/products/${this.category}/${this.subcategory}`;
			}
		},
	},
	methods: {
		getProduct: function () {
			this.loaded = false;
			console.log("Fetching product", this.endpoint);
			if (this.$parent.language == "en") {
				if (this.subSubcategory) {
					this.endpoint = `/products/${this.category}/${this.subcategory}/${this.productId}.json`;
				} else {
					this.endpoint = this.$router.currentRoute.path + ".json";
				}
			} else {
				if (this.subSubcategory) {
					this.endpoint = `/el/products/${this.category}/${this.subcategory}/${this.productId}.json`;
				} else {
					this.endpoint = "/el" + this.$router.currentRoute.path + ".json";
				}
			}
			axios
				.get(this.endpoint)
				.then(
					(response) => ((this.product = response.data), (this.loaded = true))
				);
		},
		visibilityChanged(isVisible, entry) {
			if (entry.isIntersecting === true) {
				entry.target.classList.add("revealed");
			}
		},
	},
};
</script>
