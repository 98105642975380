<style lang="scss" scoped>
.stores,
.map {
	flex-basis: 50%;
	overflow: hidden;
}

input::placeholder {
	@apply text-white;
	opacity: 0.5;
}
.store {
	svg {
		display: inline-block;
		vertical-align: baseline;
	}
}
</style>

<template>
	<main
		class="mt-header text-white .min-h-full-header h-full-header overflow-hidden"
	>
		<div class="flex flex-col md:flex-row h-full">
			<div class="stores md:w-1/2 basis-1/2">
				<div class="w-full h-full inner-pl inner-pr mb-6 lg:mb-0">
					<div class="flex flex-col h-full">
						<div class="text-sm font-bold uppercase mt-6 md:text-2xl">
							<text-reveal :text="heading"></text-reveal>
						</div>
						<div class="relative w-full md:inline-block mt-6 mb-6">
							<input
								type="text"
								class="text-sm md:text-xl bg-black border pl-2 pr-8 py-1 w-full"
								:placeholder="placeholder"
								v-model="storeFilterQuery"
							/>
							<button
								v-if="storeFilterQuery"
								class="absolute right-0 top-0 bottom-0 px-2 py-1"
								@click="storeFilterQuery = ''"
							>
								<close-mini class="block"></close-mini>
							</button>
						</div>

						<h2
							class="relative pb-1 px-2 border-b"
							v-if="location.lat && location.lng && hasResults()"
						>
							{{ this.nearestStore }}
						</h2>

						<div class="overflow-x-hidden overflow-y-auto mb-6 bg-black" v-if="hasResults()">
							<template v-for="(store, index) in this.stores">
								<div v-if="store.searchMatch" class="store border-b px-2 py-4 overflow-hidden" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)">
									<div class="text flex">
										<div class="left flex-auto">
											<h3 class="link uppercase font-bold text-sm mb-2">
												{{ store.name }}
											</h3>
											<p class="uppercase text-sm">
												{{ store.address }}<br />
												{{ store.city }}, {{ store.zipcode }}<br />
											</p>
										</div>
										<div class="right flex-0">
											<span v-if="store.distance" class="toggle">
												<!-- <icon-location /> {{ store.distance.toFixed(2) }} km -->
											</span>
											<button
												@click="expand(index)"
												class="transform transition-transform"
												:class="store.expanded ? 'rotate-180' : ''"
											>
												<icon-collapse />
											</button>
										</div>
									</div>
									<div
										class="expanded flex transition-opacity items-center"
										:class="
											store.expanded
												? 'opacity-100 h-auto duration-500 pt-4 pb-2'
												: 'opacity-0 h-0 duration-0'
										"
									>
										<span class="phone flex-1"
											><icon-phone class="mr-2" />{{ store.phone }}</span
										>
										<a
											class="border px-4 py-2"
											:href="store.mapLink"
											target="_blank"
											>directions</a
										>
									</div>
								</div>
							</template>
						</div>

						<div
							class="error flex flex-1 flex-col items-center justify-center text-center"
							v-if="!hasResults()"
						>
							<oops class="mb-6 w-32 md:w-56"></oops>
							<div v-html="searchError" class="text-xl"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="map w-full md:pl-5 md:w-1/2 basis-1/2">
				<div id="map" class="bg-grey w-full h-full">
					<GmapMap
						:center="{
							lat: parseFloat(mapsCenterLat),
							lng: parseFloat(mapsCenterLng),
						}"
						:zoom="parseFloat(mapsZoom)"
						:style="'width: 100%; height: 100%'"
						:options="{
							mapTypeControl: false,
							streetViewControl: false,
							styles: mapStyles,
						}"
					>

						<GmapMarker
							v-if="hoveredStore && hoveredStore.lat && hoveredStore.lng"
							:position="{
								lat: parseFloat(hoveredStore.lat),
								lng: parseFloat(hoveredStore.lng),
							}"
							:clickable="true"
							:draggable="false"
							:icon="{ url: require('../../../../assets/images/marker.png') }"
							@click="center = hoveredStore.position"
						/>

						<GmapMarker
							v-if="location.lat && location.lng"
							:position="{
								lat: parseFloat(location.lat),
								lng: parseFloat(location.lng),
							}"
							:clickable="true"
							:draggable="false"
							:icon="{
								url: require('../../../../assets/images/location-marker.png'),
								scaledSize: { width: 70, height: 70 },
								anchor: { x: 35, y: 35 },
							}"
							@click="center = store.position"
						/>
					</GmapMap>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			location: {},
			heading: "",
			placeholder: "",
			nearestStore: "",
			searchError: "",
			stores: [],
			storeFilterQuery: "",
			storeFilterKeys: ["name", "address", "city", "zipcode"],
			hoveredStore: {},
			mapsCenterLat: 0,
			mapsCenterLng: 0,
			mapsZoom: 10,
			mapsMarkers: [],
			mapStyles: [
				{
					featureType: "administrative.province",
					elementType: "all",
					stylers: [
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "administrative.locality",
					elementType: "labels",
					stylers: [
						{
							lightness: "-8",
						},
					],
				},
				{
					featureType: "administrative.locality",
					elementType: "labels.text.fill",
					stylers: [
						{
							color: "#000000",
						},
					],
				},
				{
					featureType: "administrative.locality",
					elementType: "labels.text.stroke",
					stylers: [
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "administrative.neighborhood",
					elementType: "all",
					stylers: [
						{
							color: "#acacac",
						},
					],
				},
				{
					featureType: "administrative.neighborhood",
					elementType: "labels.text.fill",
					stylers: [
						{
							color: "#484848",
						},
					],
				},
				{
					featureType: "administrative.neighborhood",
					elementType: "labels.text.stroke",
					stylers: [
						{
							color: "#ff0000",
						},
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "administrative.land_parcel",
					elementType: "all",
					stylers: [
						{
							lightness: "-3",
						},
					],
				},
				{
					featureType: "landscape",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: "72",
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "landscape",
					elementType: "labels",
					stylers: [
						{
							lightness: "23",
						},
					],
				},
				{
					featureType: "poi",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: "30",
						},
						{
							visibility: "off",
						},
					],
				},
				{
					featureType: "road",
					elementType: "all",
					stylers: [
						{
							lightness: "-19",
						},
					],
				},
				{
					featureType: "road",
					elementType: "geometry",
					stylers: [
						{
							lightness: "2",
						},
						{
							gamma: "1.21",
						},
					],
				},
				{
					featureType: "road",
					elementType: "geometry.stroke",
					stylers: [
						{
							visibility: "off",
						},
						{
							saturation: "15",
						},
						{
							hue: "#ff0000",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels",
					stylers: [
						{
							lightness: "-43",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.text",
					stylers: [
						{
							visibility: "on",
						},
						{
							lightness: "22",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.text.fill",
					stylers: [
						{
							weight: "0.12",
						},
						{
							lightness: "-23",
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.text.stroke",
					stylers: [
						{
							visibility: "off",
						},
						{
							lightness: "71",
						},
					],
				},
				{
					featureType: "road",
					elementType: "labels.icon",
					stylers: [
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "road.highway",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							visibility: "simplified",
						},
					],
				},
				{
					featureType: "road.arterial",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: 30,
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "road.local",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							lightness: 40,
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "transit",
					elementType: "all",
					stylers: [
						{
							saturation: -100,
						},
						{
							visibility: "simplified",
						},
					],
				},
				{
					featureType: "transit",
					elementType: "geometry.fill",
					stylers: [
						{
							saturation: "5",
						},
						{
							visibility: "on",
						},
						{
							lightness: "5",
						},
					],
				},
				{
					featureType: "water",
					elementType: "geometry",
					stylers: [
						{
							hue: "#ffff00",
						},
						{
							lightness: "-24",
						},
						{
							saturation: -97,
						},
					],
				},
				{
					featureType: "water",
					elementType: "geometry.fill",
					stylers: [
						{
							saturation: "-88",
						},
						{
							lightness: "-23",
						},
						{
							visibility: "on",
						},
					],
				},
				{
					featureType: "water",
					elementType: "labels",
					stylers: [
						{
							visibility: "on",
						},
						{
							lightness: -25,
						},
						{
							saturation: -100,
						},
					],
				},
				{
					featureType: "water",
					elementType: "labels.text",
					stylers: [
						{
							weight: "0.01",
						},
						{
							lightness: "9",
						},
					],
				},
				{
					featureType: "water",
					elementType: "labels.text.fill",
					stylers: [
						{
							lightness: "-32",
						},
						{
							gamma: "2.99",
						},
					],
				},
			],
		};
	},
	mounted() {
		this.getData();

		this.$getLocation({})
			.then((coordinates) => {
				this.location = coordinates;
				this.setStoresDistance();
				this.filterStores();
			})
			.catch((error) => {
				// console.log(error);
			});
	},
	methods: {
		getData: function () {
			this.endpoint = this.$router.currentRoute.path + ".json";
			// If language is el prepend language segment to the endpoint
			if (this.$parent.language == "el") {
				this.endpoint = "/el" + this.endpoint;
			}

			axios.get(this.endpoint).then((response) => {
				this.heading = response.data.heading;
				this.placeholder = response.data.placeholder;
				this.nearestStore = response.data.nearestStore;
				this.searchError = response.data.searchError;
				this.storesSortedByLocation = response.data.stores;
				this.stores = response.data.stores;
				this.mapsCenterLat = response.data.mapsCenterLat;
				this.mapsCenterLng = response.data.mapsCenterLng;
				this.mapsZoom = response.data.mapsZoom;

				this.setStoresDistance();
				this.filterStores();
			});
		},
		filterStores: function () {
			this.stores.map((store) => {
				store.searchMatch = store.searchString.toLowerCase().includes(this.storeFilterQuery.toLowerCase())
				return store;
			});
		},
		hasResults: function () {
			return this.stores.filter((store) => store.searchMatch).length > 0;
		},
		hasHovered: function () {
			return this.stores.filter((store) => store.hovered).length > 0;
		},
		getDistance: function (lat1, lon1, lat2, lon2) {
			var R = 6371; // Radius of the earth in km
			var dLat = this.deg2rad(lat2 - lat1); // this.deg2rad below
			var dLon = this.deg2rad(lon2 - lon1);
			var a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(this.deg2rad(lat1)) *
					Math.cos(this.deg2rad(lat2)) *
					Math.sin(dLon / 2) *
					Math.sin(dLon / 2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			var d = R * c; // Distance in km
			return d;
		},
		deg2rad: function (deg) {
			return deg * (Math.PI / 180);
		},
		sortStores: function () {
			// sort by distance or name
			if (this.location.lat && this.location.lng && this.stores.length > 0) {
				this.stores.sort((a, b) => a.distance - b.distance);
			} else {
				this.stores.sort((a, b) => a.name.localeCompare(b.name));
			}
		},
		setStoresDistance: function () {
			if (this.location.lat && this.location.lng && this.stores.length > 0) {
				this.stores.map((store) => {
					store.distance = this.getDistance(
						this.location.lat,
						this.location.lng,
						parseFloat(store.lat),
						parseFloat(store.lng)
					);
					return store;
				});
			}
		},
		expand: function (index) {
			this.stores[index].expanded = !this.stores[index].expanded;
		},
		mouseEnter: function (index) {

			// const hoveredStore = this.stores[index];
			this.hoveredStore = this.stores[index];

			// if (hoveredStore.lat && hoveredStore.lng) {
			// 	this.hoveredStore = this.stores[index];
			// }
		},
		mouseLeave: function (index) {
			// this.stores[index].hovered = false;
		},
	},
	watch: {
		storeFilterQuery: function (val) {
			this.filterStores();
		},
	},
};
</script>
