<style lang="css" scoped>
figure img {
	opacity: 1;
	@apply transition-opacity;
	@apply duration-300;
	@apply w-full;
}
figure img[data-src] {
	opacity: 0;
}

.image-reveal {
	position: relative;
}
.image-reveal > div {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	transition: height 0.65s;
	@apply z-10;
}
.image-reveal.revealed > div {
	height: 0;
}
</style>
<template>
	<figure
		v-observe-visibility="{
			callback: visibilityChanged,
			throttle: 300,
			once: true,
		}"
		:class="[isRevealed ? 'revealed' : '', 'image-reveal']"
	>
		<img v-onload="imageSrc" alt="" />
		<div :class="bgColor"></div>
	</figure>
</template>
<script>
export default {
	props: {
		imageSrc: {},
		bgColor: {},
	},
	data: function () {
		return {
			isRevealed: false,
		};
	},
	methods: {
		visibilityChanged(isVisible, entry) {
			this.isRevealed = isVisible;
		},
	},
};
</script>
