<style lang="css" scoped>
div {
	position: relative;
	@apply pl-16;
	@apply h-12;
}
input {
	position: absolute;
	left: 0;
	@apply w-12;
	@apply h-12;
	opacity: 0;
}
label {
	position: relative;
	line-height: 3rem;
	user-select: none;
}
label::before {
	content: "";
	position: absolute;
	left: -4.2rem;
	display: block;
	@apply w-12;
	@apply h-12;
	pointer-events: none;
	@apply border;
	@apply border-white;
	border-radius: 100%;
}
.disabled label {
	opacity: 0.2;
}

div.small {
	@apply pl-6;
	@apply h-4;
}
div.small input {
	@apply w-3;
	@apply h-3;
}
div.small label {
	line-height: 1.5;
	padding-top: 6px;
}
div.small label::before {
	left: -1.5rem;
	top: 8px;
	@apply w-3;
	@apply h-3;
}

input:checked ~ label::before {
	@apply bg-white;
}
</style>

<template>
	<div :class="{ small: isSmall, disabled: isDisabled }">
		<input
			:id="id"
			type="checkbox"
			:value="inputValue"
			:disabled="isDisabled == true"
			v-model="checked"
			@change="onChange"
		/>
		<label
			:class="[isSmall ? '' : 'text-2xl font-bold', 'text-xs uppercase block']"
			:for="id"
		>
			{{ label }}
		</label>
	</div>

	<!-- 	<div class="custom-control custom-checkbox">
		<input type="checkbox" class="custom-control-input" id="customCheck1">
		<label class="custom-control-label" for="customCheck1">Check this custom checkbox</label>
	</div> -->
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		isDisabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: true,
			default: "Label",
		},
		isSmall: {
			type: Boolean,
			default: false,
		},
		// checked: {
		// 	type: Boolean,
		// 	default: false
		// },
		inputValue: {
			type: String,
		},
		value: {
			type: Array,
		},
		val: {
			type: String,
		},
	},
	data: function () {
		return {
			checkedProxy: false,
		};
	},
	computed: {
		checked: {
			get() {
				return this.value;
			},
			set(val) {
				this.checkedProxy = val;
			},
		},
	},
	methods: {
		onChange: function (e) {
			if (this.value) {
				let currentValue = [...this.value];
				if (e.target.checked) {
					currentValue.push(e.target.value);
				} else {
					currentValue = currentValue.filter((item) => item !== e.target.value);
				}
				this.$emit("input", currentValue);
			}
		},
	},
};
</script>
