<style lang="css" scoped>
div {
	position: relative;
	@apply pl-16;
	@apply h-12;
}
input {
	position: absolute;
	left: 0;
	@apply w-12;
	@apply h-12;
	opacity: 0;
}
label {
	position: relative;
	line-height: 3rem;
}
label::before {
	content: "";
	position: absolute;
	left: -4.2rem;
	display: block;
	@apply w-12;
	@apply h-12;
	pointer-events: none;
	@apply border;
	@apply border-white;
	border-radius: 100%;
	transition: background 0.25s;
}

div.small {
	@apply pl-6;
	@apply h-4;
}
div.small input {
	@apply w-3;
	@apply h-3;
}
div.small label {
	line-height: 1rem;
}
div.small label::before {
	left: -1.5rem;
	top: 1px;
	@apply w-3;
	@apply h-3;
}

input:checked ~ label::before {
	@apply bg-white;
}
</style>

<template>
	<div :class="{ small: isSmall }">
		<input
			:id="id"
			type="checkbox"
			:checked="value"
			v-on:input="$emit('input', $event.target.checked)"
		/>
		<label
			:class="[isSmall ? '' : 'text-2xl font-bold', 'text-xs uppercase block']"
			:for="id"
		>
			{{ label }}
		</label>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: false,
		},
		label: {
			type: String,
			required: true,
			default: "Label",
		},
		isSmall: {
			type: Boolean,
			default: false,
		},
		value: {},
	},
	data: function () {
		return {};
	},
};
</script>
